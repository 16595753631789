import React, { Component } from "react";
import PreviewColors from "./PreviewColors";
import PreviewAdvanced from "./PreviewAdvanced";

export default class PreviewPanel extends Component {
  render() {
    if (!this.props.fileDetails.fileName) return null;

    return (
      <div id="preview-panel" className="panel">
        <div className="panel-title">Pattern Preview</div>
        <div className="panel-contents hcenter">
          <PreviewColors
            settings={this.props.settings}
            config={this.props.config}
            onChangeColor={this.props.onChangeColor}
            meta={this.props.meta}
          />
          <PreviewAdvanced
            settings={this.props.settings}
            config={this.props.config}
            fileDetails={this.props.fileDetails}
            meta={this.props.meta}
          />
        </div>
      </div>
    );
  }
}
