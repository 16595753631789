import React, { Component } from "react";
import { toolSelect } from "./ComponentCommon";

const FILAMENT_CHANGE_TOOL_TIP = "The tool to be changed";
const FILAMENT_CHANGE_COLOR_TIP = "The new color is only used in the preview and has no effect on the resulting file";

export default class ModPanelFilamentChange extends Component {
    getWarning() {
        const chgMods = this.props.config.filamentChangeMods.filter(
            m => m.filamentChangeHeight === this.props.m.filamentChangeHeight
        );

        // Check if it's the last filament change at the height. Only the last one needs a warning.
        if (this.props.m !== chgMods[chgMods.length - 1]) return "";

        const mods = this.props.config.blockMods.filter(
            m => m.isMixing() && m.inRange(this.props.m.filamentChangeHeight)
        );
        if (mods.length > 0)
            return (
                `Warning: A change at this height will load filament while a mixing modifier is in use. ` +
                `This will cause an unwanted line in the print unless the mix is 100% of ${this.props.m.filamentChangeTool} at this height. ` +
                `If the mix at this height is 100% of another tool, consider adding another filament change after this to reload that tool into the nozzle.`
            );

        const lineNum = this.props.fileDetails.obj.heightToLineTree.findCeiling(this.props.m.filamentChangeHeight);
        const actTool = this.props.meta.lineToToolTree.findFloor(lineNum);

        return !this.props.settings.isSingleExtruder && actTool !== this.props.m.filamentChangeTool
            ? `Warning: A change at this height will load filament into the nozzle from ${this.props.m.filamentChangeTool} while ${actTool} is active. ` +
                  `This will cause an unwanted line in the print.`
            : null;
    }

    render() {
        const warning = this.getWarning();

        return (
            <div className="panel-contents">
                {toolSelect(
                    this.props.settings,
                    this.props.m,
                    this.props.settings.physicalTools,
                    "Filament Change Tool",
                    "filamentChangeTool",
                    "",
                    FILAMENT_CHANGE_TOOL_TIP,
                    this.props.onChangeValue
                )}
                {this.filamentChangeColor(this.props.m, this.props.onChangeValue, FILAMENT_CHANGE_COLOR_TIP)}
                {warning ? (
                    <div className="panel-item" style={{ color: "red", minHeight: "26px", lineHeight: "26px" }}>
                        {warning}
                    </div>
                ) : null}
            </div>
        );
    }

    filamentChangeColor(s, onChange, title) {
        return (
            <div className="panel-item" title={title}>
                <label htmlFor="filamentChangeColor" className="value-label">
                    New Color
                </label>
                <input type="color" name="filamentChangeColor" value={s.filamentChangeColor} onChange={onChange} />
            </div>
        );
    }
}
