import { ModType } from "./Mod";
import { Pattern } from "../Pattern";
import ModCustomGcode from "./ModCustomGcode";
import ModFilamentChange from "./ModFilamentChange";
import ModGradient from "./ModGradient";
import ModSolid from "./ModSolid";
import ModStripes from "./ModStripes";
import ModRandoMix from "./ModRandoMix";
import ModRandoTool from "./ModRandoTool";
import ModVirtualMix from "./ModVirtualMix";
import ModStripesAdvanced from "./ModStripesAdvanced";
import ModHeightPatterns from "./ModHeightPatterns";

function modTypeToClass(type) {
    switch (type) {
        case ModType.STRIPES:
            return ModStripes;
        case ModType.GRADIENT:
            return ModGradient;
        case ModType.RANDO_MIX:
            return ModRandoMix;
        case ModType.RANDO_TOOL:
            return ModRandoTool;
        case ModType.FILAMENT_CHANGE:
            return ModFilamentChange;
        case ModType.VIRTUAL_MIX:
            return ModVirtualMix;
        case ModType.STRIPES_ADVANCED:
            return ModStripesAdvanced;
        case ModType.CUSTOM_GCODE:
            return ModCustomGcode;
        case ModType.SOLID:
            return ModSolid;
        case ModType.HEIGHT_PATTERNS:
            return ModHeightPatterns;
        default:
            return null;
    }
}

function createModByType(args) {
    let cls = modTypeToClass(args.type);
    return new cls(args);
}

function copyMod(m, type = null) {
    return createModByType({ ...m, patterns: [...m.patterns], type: type || m.type });
}

function defaultMod(settings, id, startHeight, endHeight = null, fileDetails = null) {
    return createModByType({
        type: ModType.STRIPES,
        id: id,
        startHeight: startHeight,
        endHeight: endHeight != null ? endHeight : fileDetails ? fileDetails.obj.maxHeight : 1,
        patterns: [new Pattern("T0", 2, 6), new Pattern("T1", 2, 0.8)].concat(
            settings && settings.toolCnt > 2 ? [new Pattern("T2", 2, 2)] : []
        )
    });
}

export { modTypeToClass, createModByType, copyMod, defaultMod };
