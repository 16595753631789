import React, { Component } from "react";

export default class ErrorPanel extends Component {
    render() {
        if (!this.props.error && !this.props.warning) return null;

        const style = this.props.error ? errorStyle : warningStyle;
        const text = this.props.error || this.props.warning;

        return (
            <div className="panel error">
                <div className="panel-contents hcenter flex-column">
                    {text.split("\n").map((txt, i) => (
                        <div style={style} key={i}>
                            {txt}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const errorStyle = {
    fontSize: "larger",
    fontWeight: "bold",
    color: "red"
};

const warningStyle = {
    fontSize: "larger",
    color: "orange"
};
