import React from "react";
import { toolSelect, range, getT0PctFromWeights } from "./ComponentCommon";
import {} from "../utils/utils";

const VIRTUAL_TOOL_TIP = "The tool which will be assigned the mix at the beginning of the file";

export default function ModPanelVirtualMix({ settings, m, onChangeValue, onChangePct, onChangeWeight }) {
  const weights = () => {
    return (
      <React.Fragment>
        {settings.toolCnt > 2
          ? settings.physicalTools.map(t => weightForTool(t, "virtualMixWeights"))
          : range("T0 %", "virtualMixWeights", getT0PctFromWeights(m.virtualMixWeights), 0, 100, "", onChangePct)}
      </React.Fragment>
    );
  };

  const weightForTool = (tool, name) => {
    const weight = m[name][tool] || 0;
    const sum = settings.physicalTools.reduce((sum, t) => sum + (m[name][t] || 0), 0);
    const pct = sum ? Math.round((100 * weight) / sum) : 0;

    return (
      <div className="flex-row panel-item" key={tool}>
        {range(tool, name, weight, 0, 255, "", ev => onChangeWeight(ev, tool), 1, "flex-row vcenter")}
        <div className="vcenter" style={{ width: "5ch", textAlign: "right" }}>
          {pct}%
        </div>
      </div>
    );
  };

  if (settings.virtualTools.length === 0) {
    return (
      <div className="panel-item" style={{ color: "red", minHeight: "26px", lineHeight: "26px" }}>
        There are no virtual tools configured. Please increase the virtual tool count in the settings.
      </div>
    );
  }

  return (
    <React.Fragment>
      {toolSelect(
        settings,
        m,
        settings.virtualTools,
        "Mix Tool",
        "virtualMixTool",
        "",
        VIRTUAL_TOOL_TIP,
        onChangeValue,
        "Please select a valid virtual tool"
      )}
      {weights()}
    </React.Fragment>
  );
}
