import React from "react";
import { MAX_LEVELS, sessionInfo } from "./globals";

function round(val, factor = 100) {
    return Math.round(val / factor) * factor;
}

function boolVal(val) {
    if (val === true || val === "true") return true;
    if (val === false || val === "false") return false;
    return null;
}

function stepRound(v, minStep) {
    if (minStep === null) return v;

    const factor = 1 / minStep;
    return Math.round(v * factor) / factor;
}

function stepFloor(v, minStep) {
    if (minStep === null) return v;

    const factor = 1 / minStep; // Avoid rounding errors with fractions
    return Math.floor(v * factor) / factor;
}

function isNumber(v) {
    if (typeof v === "number" && isFinite(v)) return true;
    if (typeof v === "string") {
        if (isNaN(v)) return false;

        return !["", null, true].includes(v.trim());
    }

    return false;
}

function getNumber(v) {
    if (typeof v === "number" && isFinite(v)) return Number(v);
    if (typeof v === "string") {
        if (isNaN(v)) return null;

        return !["", null, true].includes(v.trim()) ? Number(v) : null;
    }

    return null;
}

function numberOrValue(value, minStep = null, min = undefined, max = undefined) {
    if (value === "") return value;

    let v = Number(value);
    if (!isNumber(v)) return value;

    v = stepRound(v, minStep);

    if (min !== undefined) v = Math.max(min, v);
    if (max !== undefined) v = Math.min(max, v);

    return v;
}

function numBoolVal(value, minStep = null, min = undefined, max = undefined) {
    const bool = boolVal(value);
    if (bool !== null) return bool;

    return numberOrValue(value, minStep, min, max);
}

function numToWidth(v, decimals = 0, padding = 4) {
    const str = decimals > 0 && isNumber(v) ? v.toFixed(decimals) : v.toString();
    return str.length + padding + "ch";
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function sumValues(obj) {
    return Object.values(obj).reduce((sum, v) => sum + v, 0);
}

// https://stackoverflow.com/a/29450606/1819191
const seededPRNG = function (s) {
    var mask = 0xffffffff;
    var m_w = (123456789 + s) & mask;
    var m_z = (987654321 - s) & mask;

    return function () {
        m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
        m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;

        var result = ((m_z << 16) + (m_w & 65535)) >>> 0;
        result /= 4294967296;
        return result;
    };
};

function getMaxLevelsByStep(settings, fd, minStep = 0.1) {
    if (!fd || !fd.obj) return MAX_LEVELS;

    const step = Math.max(minStep, settings.minStep);
    return Math.min(MAX_LEVELS, Math.max(100, Math.ceil(fd.obj.maxHeight / step / 100) * 100));
}

function getMaxEndHeight(settings, fd) {
    return stepFloor(fd.obj.maxHeight + settings.minStep, settings.minStep);
}

function textLink(text, url) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );
}

function imageLink(img, url) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <img src={img} alt="info" style={{ width: "16px", height: "16px", marginLeft: "1ch" }} />
        </a>
    );
}

function boomOdd(s, v) {
    return s.boomerang && v % 2 === 0 ? v + 1 : v;
}

function getMixResetCmds(settings, tool) {
    if (settings.isSingleExtruder) return [];

    return settings.physicalTools
        .map(t => `M163 S${t.slice(1)} P${t === tool ? 1 : 0}`)
        .concat(`M164 S${tool.slice(1)} ; Reset the mix`);
}

function getFilamentChangeCmds(settings, tool) {
    return settings.filamentChangeText
        .replace(new RegExp("%T", "g"), tool)
        .replace(new RegExp("%t", "g"), tool.slice(1))
        .trim()
        .split("\n")
        .map(l => l + "\n");
}

function separateFileName(fileName) {
    const index = fileName.lastIndexOf(".");
    if (index < 0) return [fileName, ""];
    return [fileName.slice(0, index), fileName.slice(index + 1)];
}

function getOutFileName(settings, fileDetails, config) {
    const index = fileDetails.fileName.lastIndexOf(".");
    if (index < 0) return fileDetails.fileName + ".gcmm";

    const [name, ext] = separateFileName(fileDetails.fileName);
    return settings.outFileFormat
        .replace(new RegExp("%f", "g"), name)
        .replace(new RegExp("%e", "g"), ext)
        .replace(new RegExp("%M", "g"), config.simpleSummary)
        .replace(new RegExp("%m", "g"), config.simpleSummary.toLowerCase());
}

function safeFn(fn, fallback) {
    try {
        return fn() || fallback;
    } catch (e) {
        console.trace([e]);
        return fallback;
    }
}

function requestPersistence() {
    if (navigator.storage && navigator.storage.persist)
        navigator.storage.persist().then(function (persists) {
            // console.log(`Storage persistence request ${persists ? "accepted" : "denied"}`);
            sessionInfo.storage_persists = persists;
        });
}

export {
    boolVal,
    boomOdd,
    getFilamentChangeCmds,
    getMaxEndHeight,
    getMaxLevelsByStep,
    getMixResetCmds,
    getNumber,
    getOutFileName,
    getRandomInt,
    isNumber,
    imageLink,
    numberOrValue,
    numBoolVal,
    numToWidth,
    requestPersistence,
    round,
    safeFn,
    seededPRNG,
    separateFileName,
    stepRound,
    sumValues,
    textLink,
};
