import { Mod } from "./Mod";
import { Commands } from "../Commands";

export default class ModStripes extends Mod {
  fillMeta(settings, fileDetails, meta) {
    let index = 0;
    const endHeight = Math.min(this.endHeight, fileDetails.obj.maxHeight + 0.00001);

    for (let height = this.startHeight; height < endHeight; ) {
      const p = this.patterns[index++ % this.patterns.length];
      const node = fileDetails.obj.heightToLineTree.findCeilingNode(height);
      if (node === null) break;

      const prevTool = meta.lineToToolTree.findFloor(node.data);

      meta.lineToToolTree.replaceIfChanged(node.data, p.tool);

      meta.newCommands.add(
        node.data,
        new Commands(this, height, this.getToolChangeCmds(settings, p.tool, prevTool, height))
      );

      height += p.height;
    }
  }

  getStartTool(meta) {
    return this.patterns[0].tool;
  }
}
