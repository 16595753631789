import React, { Component } from "react";
import { ModType, Pattern, getModTypes } from "../classes/mods/Mod";
import { copyMod, defaultMod } from "../classes/mods/ModUtils";
import { numberOrValue, numBoolVal, getMaxEndHeight } from "../utils/utils";
import { toolSelect } from "./ComponentCommon";
import ModPanelRandoMix from "./ModPanelRandoMix";
import ModPanelSolid from "./ModPanelSolid";
import ModPanelStripes from "./ModPanelStripes";
import ModPanelGradient from "./ModPanelGradient";
import ModPanelFilamentChange from "./ModPanelFilamentChange";
import ModPanelVirtualMix from "./ModPanelVirtualMix";
import ModPanelRandoTool from "./ModPanelRandoTool";
import Slider from "./Slider";
import ModPanelCustomGcode from "./ModPanelCustomGcode";
import { gaEvt } from "../utils/ga";

const START_HEIGHT_TIP = "The first tool change for this modifier will occur at this height";
const END_HEIGHT_TIP =
  "The end height for this modifier (exclusive).\n" +
  "At the end height, the GRADIENT TOOL mix will be restored and the END TOOL will be inserted if configured.";
const END_TOOL_TIP =
  "The tool will be set to this at the end of the modifier.\n" +
  "Leave it blank to leave the tool the same as it was in the original file.";

const FILAMENT_CHANGE_HEIGHT_TIP =
  "The height for changing the filament using the G-code script in the G-Code Mix Master settings";

const MOD_TYPE_TIPS = {
  [ModType.FILAMENT_CHANGE]:
    "This will insert a filament change at the given height\nThe commands for this can be customized in the settings\n" +
    "Filament Changes require ADVANCED_PAUSE_FEATURE to be enabled in the firmware",
  [ModType.VIRTUAL_MIX]:
    "This will set a virtual tool using a mix of physical tools which can then be used in other modifiers",
  [ModType.RANDO_MIX]: "This will set a different random mix of the selected physical tools for each level",
  [ModType.RANDO_TOOL]:
    "This will set a different tool from the selected physical and virtual mix tools for each level",
  [ModType.STRIPES]: "This will perform a stripes pattern by changing the active extruder at the configured heights",
  [ModType.STRIPES_ADVANCED]:
    "This will perform a stripes pattern where the stripes can grow or shrink as the pattern progresses",
  [ModType.STRIPED_GRADIENT]: "This will overlap a stripes pattern with a gradient",
  [ModType.SOLID]:
    "This will set the tool between the start and end height with no tool changes which will result in a solid region",
  [ModType.CUSTOM_GCODE]: "This is an advanced feature that will insert custom G-code at specific heights\n",
};

export default class ModPanel extends Component {
  render() {
    const m = this.props.mod;
    const fd = this.props.fileDetails;
    const minHeight = 0;
    const maxHeight = fd.obj.maxHeight;
    const props = {
      fileDetails: fd,
      config: this.props.config,
      settings: this.props.settings,
      meta: this.props.meta,
      m,
      onChangeValue: this.onChangeValue,
      onChangeField: this.onChangeField,
      onChangeWeight: this.onChangeWeight,
      onChangePct: this.onChangePct,
      onAddPattern: this.onAddPattern,
      onDeletePattern: this.onDeletePattern,
      onChangeRandoTool: this.onChangeRandoTool,
      onAddMix: this.onAddMix,
      onDeleteMix: this.onDeleteMix,
    };

    return (
      <div className="config-mod">
        {this.btnDeleteMod(this.onDeleteMod)}
        <div className="panel-title mod-title">Modifier {m.id}</div>
        <div className="panel-contents">
          {this.modTypes(m, this.onChangeType)}
          {this.startHeight(m, minHeight, maxHeight)}
          {this.endHeight(m, minHeight, getMaxEndHeight(this.props.settings, fd))}
          {this.filamentChangeHeight(m, minHeight, maxHeight, this.onChangeValue)}
          {this.endTool(m, this.onChangeValue)}
          {m.type === ModType.VIRTUAL_MIX ? <ModPanelVirtualMix {...props} /> : null}
          {m.type === ModType.FILAMENT_CHANGE ? <ModPanelFilamentChange {...props} /> : null}
          {m.type === ModType.SOLID ? <ModPanelSolid {...props} /> : null}
        </div>
        {m.type === ModType.RANDO_MIX ? <ModPanelRandoMix {...props} /> : null}
        {m.type === ModType.RANDO_TOOL ? <ModPanelRandoTool {...props} /> : null}
        {[ModType.STRIPES, ModType.STRIPES_ADVANCED].includes(m.type) ? <ModPanelStripes {...props} /> : null}
        {m.type === ModType.GRADIENT ? <ModPanelGradient {...props} /> : null}
        {m.type === ModType.CUSTOM_GCODE ? <ModPanelCustomGcode {...props} /> : null}
      </div>
    );
  }

  btnDeleteMod(onDelete) {
    const title = this.props.config.mods.length === 1 ? "Reset modifier" : "Delete modifier";

    return (
      <img
        className="btn btn-small btn-mod-delete btn-delete"
        src={require("../resources/btn-x.svg")}
        onClick={onDelete}
        title={title}
        alt="x"
      />
    );
  }

  modTypes(s, onChange) {
    return (
      <div className="panel-item">
        <select name="mod-type" className="combo" value={s.type} onChange={onChange}>
          {getModTypes(this.props.settings).map(t => (
            <option value={t} key={t} title={MOD_TYPE_TIPS[t] || ""}>
              {t}
            </option>
          ))}
        </select>
      </div>
    );
  }

  startHeight(m, minHeight, maxHeight) {
    if (m.type === ModType.FILAMENT_CHANGE || m.type === ModType.VIRTUAL_MIX) return null;

    return this.heightSlider(
      "Start Height",
      "startHeight",
      m.startHeight,
      minHeight,
      maxHeight,
      START_HEIGHT_TIP,
      this.onChangeStartHeight
    );
  }

  endHeight(m, minHeight, maxHeight) {
    if (m.type === ModType.FILAMENT_CHANGE || m.type === ModType.VIRTUAL_MIX) return null;

    return this.heightSlider(
      "End Height",
      "endHeight",
      m.endHeight,
      minHeight,
      maxHeight,
      END_HEIGHT_TIP,
      this.onChangeEndHeight
    );
  }

  filamentChangeHeight(m, minHeight, maxHeight, onChange) {
    if (m.type !== ModType.FILAMENT_CHANGE) return null;

    return this.heightSlider(
      "Filament Change Height",
      "filamentChangeHeight",
      m.filamentChangeHeight,
      minHeight,
      maxHeight,
      FILAMENT_CHANGE_HEIGHT_TIP,
      onChange
    );
  }

  heightSlider(label, name, val, min, max, title, onChange) {
    return (
      <div className="panel-item flex-row" title={title}>
        <div className="vcenter">{label}</div>
        <Slider
          label={label}
          name={name}
          val={val}
          min={min}
          max={max}
          title={title}
          idx=""
          onChange={onChange}
          step={this.props.settings.minStep}
          classes="panel-item flex-row"
        />
        <span style={{ paddingLeft: "1ch" }}></span>
      </div>
    );
  }

  endTool(m, onChange) {
    if ([ModType.FILAMENT_CHANGE, ModType.VIRTUAL_MIX, ModType.CUSTOM_GCODE].includes(m.type)) return null;

    return toolSelect(
      this.props.settings,
      m,
      this.props.meta.toolsWithBlank,
      "End Tool",
      "endTool",
      "",
      END_TOOL_TIP,
      onChange,
      "Put the original tool back after this section"
    );
  }

  // Event Handlers
  onChangeType = ev => {
    if (ev.target.value !== ModType.STRIPED_GRADIENT) {
      this.props.onChangeMod(copyMod(this.props.mod, ev.target.value));
      return;
    }

    const ok = window.confirm("Is it ok to replace the current modifiers with new ones?");
    if (!ok) return;

    const endHeight = getMaxEndHeight(this.props.settings, this.props.fileDetails);
    const m1 = defaultMod(this.props.settings, 1, 0, endHeight, this.props.fileDetails);
    const m2 = copyMod(m1, ModType.GRADIENT);
    m2.id = 2;
    m2.overlap = true;
    m2.boomerang = true;

    if (this.props.settings.toolCnt > 2) {
      m1.patterns.splice(1, 1); // Remove the T1 stripe since T0 will use the first two tools
    } else {
      m1.patterns.splice(2, 1); // Remove the T2 stripe since T0 will use the first two tools
      m2.gradientWeights[1] = { T0: 10, T1: 90, T2: 0 };
    }

    this.props.onReplaceMods([m1, m2]);
    gaEvt("striped_gradient");
  };

  onChangeValue = ev => {
    const m = copyMod(this.props.mod);
    const idx = Number.parseInt(ev.target.getAttribute("idx"));
    const val = numBoolVal(ev.target.value, this.props.settings.minStep, ev.target.min, ev.target.max);

    // Mod level values don't have an index and pattern level ones do
    Number.isNaN(idx) ? (m[ev.target.name] = val) : (m.patterns[idx][ev.target.name] = val);

    this.props.onChangeMod(m);
  };

  onChangeField = (name, val) => {
    const m = copyMod(this.props.mod);
    m[name] = val;

    this.props.onChangeMod(m);
  };

  onChangePct = ev => {
    const m = copyMod(this.props.mod);
    const pct = numberOrValue(ev.target.value);

    m[ev.target.name] = { ...m[ev.target.name], T0: pct, T1: 100 - pct };

    this.props.onChangeMod(m);
  };

  onChangeWeight = (ev, tool) => {
    const m = copyMod(this.props.mod);
    m[ev.target.name] = {
      ...m[ev.target.name],
      [tool]: numberOrValue(ev.target.value, null, ev.target.min, ev.target.max),
    };
    this.props.onChangeMod(m);
  };

  onChangeStartHeight = ev => {
    const m = copyMod(this.props.mod);
    const v = parseFloat(ev.target.value);

    m.startHeight = v;
    if (v > this.props.mod.endHeight) m.endHeight = v;

    this.props.onChangeMod(m);
  };

  onChangeEndHeight = ev => {
    const m = copyMod(this.props.mod);
    const v = parseFloat(ev.target.value);

    m.endHeight = v;
    if (v < this.props.mod.startHeight) m.startHeight = v;

    this.props.onChangeMod(m);
  };

  onChangeRandoTool = (tool, val) => {
    const m = copyMod(this.props.mod);
    m.randoTools = { ...m.randoTools, [tool]: val };
    this.props.onChangeMod(m);
  };

  onAddPattern = ev => {
    const m = copyMod(this.props.mod);
    const lastTool = m.patterns[m.patterns.length - 1].tool;
    m.patterns.push(new Pattern(lastTool === "T0" ? "T1" : "T0"));
    this.props.onChangeMod(m);
  };

  onDeletePattern = idx => {
    const m = copyMod(this.props.mod);
    m.patterns = [...m.patterns];
    m.patterns.splice(idx, 1);
    this.props.onChangeMod(m);
  };

  onAddMix = ev => {
    const m = copyMod(this.props.mod);
    m.gradientWeights = m.gradientWeights.concat(this.getNewMix());
    this.props.onChangeMod(m);
  };

  onDeleteMix = idx => {
    const m = copyMod(this.props.mod);
    m.gradientWeights = [...m.gradientWeights];
    m.gradientWeights.splice(idx, 1);
    if (m.gradientWeights.length < 2) m.gradientWeights.push(this.getNewMix());

    this.props.onChangeMod(m);
  };

  getNewMix() {
    return this.props.settings.physicalTools.reduce((map, t, i) => {
      return { ...map, [t]: i === 0 ? 100 : 0 };
    }, {});
  }

  onDeleteMod = ev => {
    this.props.onDeleteMod(this.props.mod.id);
  };
}
