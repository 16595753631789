import React, { Component } from "react";
import YesNoItem from "./YesNoItem";
import { range, toolSelect } from "./ComponentCommon";
import { getMaxLevelsByStep } from "../utils/utils";

const BOOM_TIP = "If enabled, the random pattern will be symmetric from bottom to top";
const SEED_TIP =
  "Each seed value will produce a unique random sequence which can be reproduced using the same seed later";
const OVERLAP_TIP =
  "Enabled this advanced feature to apply a random mix to a physical tool while leaving tool changes for " +
  "another pattern or the original file in place.  See the FAQ for more details.";
const RANDO_TOOL_TIP = "The tool that will have a random mix applied to it.";

export default class ModPanelRandoMix extends Component {
  render() {
    const [m, settings, onChg] = [this.props.m, this.props.settings, this.props.onChangeValue];
    const maxLevels = getMaxLevelsByStep(this.props.settings, this.props.fileDetails);

    return (
      <React.Fragment>
        <div className="panel-contents">
          <YesNoItem label="Boomerang" name="boomerang" value={m.boomerang} onChange={onChg} title={BOOM_TIP} />{" "}
          {<YesNoItem label="Overlap" name="overlap" value={m.overlap} onChange={onChg} title={OVERLAP_TIP} />}
          {m.overlap
            ? toolSelect(settings, m, settings.physicalTools, "Mix Tool", "mixTool", "", RANDO_TOOL_TIP, onChg)
            : null}
          {range("Levels", "randoLevels", m.randoLevels, 2, maxLevels, "", onChg)}
          {range("Seed", "randoSeed", m.randoSeed, 0, 1000, SEED_TIP, onChg)}
        </div>
        {this.tools()}
      </React.Fragment>
    );
  }

  tools = () => {
    return <div className="panel-contents">{this.props.settings.physicalTools.map(t => this.tool(t))}</div>;
  };

  tool = t => {
    const [minWeight, maxWeight] = this.props.m.randoWeightRange(t);
    const title =
      `Select the range of possible weights used for this tool when generating random weights for the mix.\n` +
      `For the full color range, set all tools to 0-100.\n` +
      `To disable a tool, set its range to 0-0.`;

    return (
      <div className="panel-item" key={t} title={title}>
        {t} Weight Range
        {this.weightInput(t, minWeight, 0)} -{this.weightInput(t, maxWeight, 1)}
      </div>
    );
  };

  weightInput(t, v, idx) {
    const style = { width: "6ch" };
    return (
      <input
        type="number"
        value={v}
        min="0"
        max="100"
        style={style}
        onChange={ev => this.onChangeRange(t, idx, ev)}
      ></input>
    );
  }

  onChangeRange(t, idx, ev) {
    const v = parseInt(ev.target.value);
    if (isNaN(v)) return;

    const range = [...this.props.m.randoWeightRange(t)];

    range[idx] = Math.max(0, Math.min(100, v)); // Limit the range
    if (range[0] > range[1]) range[1 - idx] = range[idx]; // Prevent the start weight from being bigger than the end weight

    this.props.onChangeField("randoWeightRanges", { ...this.props.m.randoWeightRanges, [t]: range });
  }
}
