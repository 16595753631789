import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { rgbToHexString, mixColorsByWeight, colorMapToRgbMap } from "../../utils/colors";
import { sumValues } from "../../utils/utils";

export default class ModVirtualMix extends Mod {
  fillMeta(settings, fd, meta) {
    if (!settings.virtualTools.some(t => t === this.virtualMixTool)) return;

    const height = 0;

    const colorMap = meta.heightToColorMapTree.findFloor(height);
    const rgbMap = colorMapToRgbMap(colorMap, settings.physicalTools);

    let rgb = mixColorsByWeight(settings.physicalTools, rgbMap, this.virtualMixWeights);
    meta.heightToColorMapTree.replace(height, { ...colorMap, [this.virtualMixTool]: rgbToHexString(rgb) });

    this.addMixCmds(settings, fd, meta, height);
  }

  addMixCmds(settings, fd, meta, height) {
    const tool = this.virtualMixTool;
    const lineNum = fd.obj.heightToLineTree.findCeiling(height);
    const curTool = meta.lineToToolTree.findFloor(lineNum);
    const cmds = settings.physicalTools
      .map((t, i) => `M163 S${i} P${this.getWeight(t)}`)
      .concat(`M164 S${tool.slice(1)}`)
      .concat(curTool);

    meta.newCommands.add(lineNum, new Commands(this, height, cmds));
  }

  getWeight(tool) {
    const weight = this.virtualMixWeights[tool] || 0;
    return Math.round(weight * 100, 2) / 100;
  }

  getError(settings) {
    if (settings.virtualToolCnt === 0)
      return "VIRTUAL TOOLS MUST BE CONFIGURED IN THE SETTINGS TO USE THE VIRTUAL MIX MODIFIER";

    if (sumValues(this.virtualMixWeights) === 0)
      return `THE WEIGHT FOR AT LEAST ONE TOOL MUST BE NON-ZERO FOR MODIFIER ${this.id}`;

    return "";
  }
}
