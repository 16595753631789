import React from "react";

export default function ButtonAdd({ title, onClick, style = {} }) {
  return (
    <div className="add-button">
      <img
        className="btn btn-small btn-add"
        style={style}
        src={require("../resources/btn-plus.svg")}
        onClick={onClick}
        alt="+"
        title={title}
      />
    </div>
  );
}

export { ButtonAdd };
