import React, { useState } from "react";

import { process } from "../utils/process";
import { octo } from "../utils/octoprint";
import { cogoError, cogoSuccess } from "../utils/cogo";
import { getOutFileName } from "../utils/utils";
import { gaEvt, gaGetParams } from "../utils/ga";

export default function ButtonOctoSend({ settings, fileDetails, config, meta, saveWarning, updateChangeLines }) {
    const [animating, setAnimating] = useState(false);

    const onOctoSend = evt => {
        evt.preventDefault();

        const cfg = octo.settingsToConfig(settings);

        if (cfg.error) return cogoError(cfg.error);

        saveWarning();

        const [outLines, changeLines] = process(settings, config, fileDetails, meta);

        const fileData = {
            fileName: getOutFileName(settings, fileDetails, config),
            blob: new Blob(outLines, { type: "application/octet-stream" }),
            fields: [
                ["select", true],
                ["print", settings.octoSendAndPrint]
            ]
        };

        octo.upload(cfg, fileData, resp => {
            console.log("OCTOPRINT UPLOAD RESPONSE", resp);

            if (resp.error) {
                gaEvt("octo_error", { ...gaGetParams(settings, config), error: resp.error, origError: resp.origError });
                return cogoError(resp.error);
            }

            const [local, sdcard] = [resp.files.local, resp.files.sdcard];
            const fileName = local ? local.name : sdcard ? sdcard.name : fileData.fileName;
            cogoSuccess(`File successfully sent file to OctoPrint as "${fileName}"`);
            gaEvt("octo_send", gaGetParams(settings, config));
        });

        updateChangeLines(changeLines);
    };

    if (!fileDetails.fileName || config.getError(settings, fileDetails)) return null;

    return (
        <div
            id="btn-octo-send"
            className={"file-btn-div" + (animating ? " file-btn-div-animate" : "")}
            onClick={ev => {
                setAnimating(true);
                onOctoSend(ev);
            }}
            onAnimationEnd={() => setAnimating(false)}
            title="Generate the new G-code file and send it to OctoPrint"
        >
            <img className="btn file-btn-img" src={require("../resources/btn-print.svg")} alt="Send to OctoPrint" />
        </div>
    );
}

export { ButtonOctoSend };
