import "./css/App.css";

import React, { useState, useEffect } from "react";
import streamSaver from "streamsaver";

import Config from "./classes/Config";
import FileDetails from "./classes/FileDetails";
import Metadata from "./classes/Metadata";
import Settings from "./classes/Settings";
import { defaultMod, copyMod } from "./classes/mods/ModUtils";
import packageJson from "../package.json";

import ChangeDetails from "./components/ChangeDetails";
import ErrorPanel from "./components/ErrorPanel";
import FileDetailsPanel from "./components/FileDetailsPanel";
import FileButtons from "./components/FileButtons";
import Header from "./components/Header";
import InfoModal from "./components/InfoModal";
import PreviewPanel from "./components/PreviewPanel";
import SettingsModal from "./components/SettingsModal";
import ModPanels from "./components/ModPanels";
import { DBG, EMAIL } from "./utils/globals";
import { cogoError } from "./utils/cogo";
import { getMaxEndHeight, numBoolVal, round, requestPersistence } from "./utils/utils";
import { gaInit, gaEvt, gaException } from "./utils/ga";
import { isProcessed } from "./utils/process";

if (DBG) console.log({ env: process.env });

if (process.env.NODE_ENV === "development") {
    console.log("Disabling analytics in dev mode");
    localStorage.setItem("analyticsDisabled", "true");
}

const streamSaverWarning =
    streamSaver === undefined || streamSaver.WritableStream === undefined
        ? "THIS BROWSER IS CURRENTLY DOES NOT SUPPORT SAVING THE FILE AS A STREAM.\nSAVING THE OUTPUT FILE WILL BE EXTREMELY SLOW.\nCHROME IS RECOMMENDED."
        : "";

function getFileBlobFromDataTransfer(dt) {
    return dt.items.length > 0 ? dt.items[0].getAsFile() : dt.files[0];
}

export default function App() {
    const [settings, setSettings] = useState(new Settings());
    const [config, setConfig] = useState(new Config(settings));
    const [fileDetails, setFileDetails] = useState({});
    const [meta, setMeta] = useState({});
    const [flags, setFlags] = useState({});
    const [changeLines, setChangeLines] = useState([]);

    useEffect(() => {
        requestPersistence();
        localStorage.setItem("version", packageJson.version);
        gaInit();
    }, []);

    window.onerror = (message, url, lineNo, columnNo, error) => {
        const stack = JSON.stringify(`${url}:${lineNo}:${columnNo}\n${error ? error.stack : ""}`);
        const isSilent = message.includes("pageX");
        gaException({ message, stack, isSilent, e: error }, "onerror", config);

        if (!isSilent)
            cogoError(
                `An unexpected error was encountered. If this persists, consider trying another browser or sending the gcode file to ${EMAIL} for inspection.`
            );
        return null;
    };

    function body() {
        const fcsm = { fileDetails, config, settings, meta };
        const processed = isProcessed(settings, config, fileDetails, meta);

        if (flags.fileLoading)
            return (
                <React.Fragment>
                    <div className="home-loading">Loading file.....</div>
                    <FileButtons
                        {...fcsm}
                        readFile={readFile}
                        updateChangeLines={changeLines => setChangeLines(changeLines)}
                    />
                </React.Fragment>
            );

        return (
            <React.Fragment>
                {!fileDetails.fileName ? bodyHome() : null}
                <div id="body-block" className="flex-column">
                    <div id="body-config">
                        <FileDetailsPanel
                            {...fcsm}
                            onChangeAppState={onChangeAppState}
                            onChangeConfigValue={onChangeConfigValue}
                        />
                        <ModPanels
                            {...fcsm}
                            onChangeMod={onChangeMod}
                            onAddMod={onAddMod}
                            onDeleteMod={onDeleteMod}
                            onReplaceMods={onReplaceMods}
                        />
                        <br />
                    </div>
                    <div id="body-results">
                        <ErrorPanel
                            error={config.getError(settings, fileDetails) || streamSaverWarning}
                            warning={config.getWarning(settings, fileDetails)}
                        />
                        <PreviewPanel {...fcsm} onChangeColor={onChangeColor} />
                        <br />
                    </div>
                </div>
                <FileButtons
                    {...fcsm}
                    readFile={readFile}
                    updateChangeLines={changeLines => setChangeLines(changeLines)}
                />
                {processed ? <ChangeDetails changeLines={changeLines} processed={processed} /> : null}
                {flags.settingsModal ? <SettingsModal settings={settings} onChangeAppState={onChangeAppState} /> : null}
                {flags.infoModal ? <InfoModal onChangeAppState={onChangeAppState} /> : null}
            </React.Fragment>
        );
    }

    const bodyHome = () => {
        return (
            <React.Fragment>
                <div className="home-panel home-drop">Drop a G-code file anywhere or click {openButton()}</div>
                <div className="home-panel home-demo">
                    Don't have a G-code file handy? To try a sample file, click {demoButton()}
                </div>
                <div className="home-panel home-settings">
                    To configure the number of extruders for your printer, click {settingsButton()}
                </div>
                <div className="home-panel home-info">For more details about using this tool, click {infoButton()}</div>
            </React.Fragment>
        );
    };

    const onOpen = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.onchange = e => readFile(e.target.files[0]);
        input.click();
    };

    const openButton = () => button("open", "btn-open", "Open a G-code file", onOpen);
    const demoButton = () => button("demo", "btn-demo", "Open the sample G-code file", onLoadDemoFile, " hue-rotate");
    const settingsButton = () =>
        button("settings", "btn-settings", "Settings", () => setFlags({ ...flags, settingsModal: true }));
    const infoButton = () => button("info", "btn-info", "Information", () => setFlags({ ...flags, infoModal: true }));

    const button = (id, filename, title, onClick, extraClasses = "") => {
        return (
            <img
                id={"btn-" + id}
                className={"btn header-btn-img home-btn" + extraClasses}
                onClick={onClick}
                src={require(`./resources/${filename}.svg`)}
                title={title}
                alt=" "
            />
        );
    };

    const updateMeta = (settings, fileDetails, config) => {
        setMeta(new Metadata(settings, fileDetails, config));
    };

    const onChangeConfigValue = ev => {
        const c = config.copy();
        c[ev.target.name] = numBoolVal(ev.target.value);
        setConfig(c);
        updateMeta(settings, fileDetails, c);
    };

    const onChangeMod = m => {
        const c = config.copy();
        const origStartHeight = c.mods[m.id - 1].startHeight;
        c.mods[m.id - 1] = m;

        if (settings.advanced && c.modLock && m.isBlockMod() && !m.overlaps()) {
            for (let other of c.blockMods.filter(o => o !== m && !o.overlaps())) {
                if (other.endHeight <= origStartHeight) {
                    other.startHeight = Math.min(m.startHeight, other.startHeight);
                    other.endHeight = Math.min(m.startHeight, other.endHeight);
                } else {
                    other.startHeight = Math.max(m.endHeight, other.startHeight);
                    other.endHeight = Math.max(m.endHeight, other.endHeight);
                }
            }
        }

        setConfig(c);
        updateMeta(settings, fileDetails, c);
    };

    const onAddMod = () => {
        const c = config.copy();
        const startHeight = Math.max(0, ...c.blockModsNonOverlap.map(m => m.endHeight));
        const endHeight = getMaxEndHeight(settings, fileDetails);
        c.mods.push(defaultMod(settings, c.mods.length + 1, startHeight, endHeight, fileDetails));
        setConfig(c);
        updateMeta(settings, fileDetails, c);
    };

    const onDeleteMod = id => {
        const c = config.copy();
        c.mods = c.mods.filter(m => m.id !== id).map(m => copyMod(m, m.type));
        c.mods.forEach((s, idx) => (s.id = idx + 1));
        const endHeight = getMaxEndHeight(settings, fileDetails);
        if (c.mods.length === 0) c.mods.push(defaultMod(settings, c.mods.length + 1, 0, endHeight, fileDetails));
        setConfig(c);
        updateMeta(settings, fileDetails, c);
    };

    const onReplaceMods = mods => {
        const c = config.copy();
        c.mods = mods;
        setConfig(c);
        updateMeta(settings, fileDetails, c);
    };

    const onChangeColor = (tool, color) => {
        const s = settings.copy({ colorMap: { ...settings.colorMap, [tool]: color }, save: true });
        setSettings(s);
        updateMeta(s, fileDetails, config);
    };

    const onChangeAppState = (flag, newSettings = null) => {
        setFlags({ ...flags, ...flag });
        if (newSettings) {
            setSettings(newSettings);
            updateMeta(newSettings, fileDetails, config);
        }
    };

    const onDragOver = ev => {
        ev.preventDefault();
    };

    const onDrop = ev => {
        ev.preventDefault();
        const blob = getFileBlobFromDataTransfer(ev.dataTransfer);
        if (blob != null) readFile(blob);
    };

    const readFile = fileBlob => {
        if (!fileBlob) return;

        const startMs = new Date();
        setFlags({ ...flags, fileLoading: true });

        const r = new FileReader();

        r.onload = e => parseText(e.target.result, fileBlob.name, startMs);

        r.readAsText(fileBlob);
    };

    const onLoadDemoFile = ev => {
        setFlags({ ...flags, fileLoading: true });

        import("./resources/demo.js").then(module => {
            parseText(module.text, "sample.gcode", new Date(), true);
        });
    };

    function parseText(text, filename, startMs, isDemo = false) {
        try {
            const lines = text.split("\n");
            const fd = new FileDetails(filename, lines, isDemo);
            const c = new Config(settings, fd, config.mods);
            c.newFile(settings, fileDetails, fd);

            if (DBG) console.log({ settings, fd, c });

            setFileDetails(fd);
            setConfig(c);
            updateMeta(settings, fd, c);
            setFlags({ ...flags, fileLoading: false });

            gaEvt(isDemo ? "demo" : "read_file", {
                line_count: round(lines.length, 1000),
                processing_ms: round(fd.procMs),
                total_ms: round(new Date() - startMs),
                lines_per_ms: fd.LinesPerMs,
                obj_warning: fd.obj ? fd.obj.warning : "",
                obj_error: fd.obj ? fd.obj.error : ""
            });
        } catch (e) {
            gaException(e, "parseText", config);
        }
    }

    return (
        <div id="app" className="App" onDragOver={onDragOver} onDrop={onDrop}>
            <Header onChangeAppState={onChangeAppState} />
            {body()}
        </div>
    );
}
