import React from "react";
import { numToWidth } from "..//utils/utils";
import { SliderLabelled } from "./SliderLabelled";

function range(label, name, val, min, max, title, onChange, step = 1, classes = "panel-item flex-row") {
    return (
        <SliderLabelled
            label={label}
            name={name}
            val={val}
            min={min}
            max={max}
            title={title}
            onChange={onChange}
            step={step}
            classes={classes}
        />
    );
}

function valToStyle(val, max, decimals) {
    const warn = isNaN(parseFloat(val)) ? { backgroundColor: "red", color: "white" } : {};
    return { width: numToWidth(max, decimals), ...warn };
}

function rangeInputs(name, val, min, max, step, i, fn) {
    const decimals = step >= 1 ? 0 : step === 0.1 ? 1 : 2;

    return (
        <React.Fragment>
            <input type="range" name={name} value={val} min={min} max={max} step={step} idx={i} onChange={fn} />
            <input
                type="number"
                name={name}
                value={decimals > 0 && val.toFixed ? val.toFixed(decimals) : val}
                min={min}
                max={max}
                step={step}
                idx={i}
                style={valToStyle(val, max, decimals)}
                onChange={fn}
                onKeyDown={ev => (step > 0 ? ev.preventDefault : null)}
            />
        </React.Fragment>
    );
}

function numberInput(label, name, val, min, max, title, onChange, step = 1, classes = "panel-item flex-row") {
    const decimals = step >= 1 ? 0 : step === 0.1 ? 1 : 2;

    return (
        <div className={classes} title={title}>
            <label className="value-label vcenter">{label}</label>
            <input
                type="number"
                name={name}
                value={decimals > 0 && val.toFixed ? val.toFixed(decimals) : val}
                min={min}
                max={max}
                step={step}
                style={valToStyle(val, max, decimals)}
                onChange={onChange}
                onKeyDown={ev => (step > 0 ? ev.preventDefault : null)}
            />
        </div>
    );
}

function toolSelect(settings, m, tools, label, name, idx, title, onChange, blankToolTitle = "") {
    return (
        <div className="panel-item" title={title}>
            {label}
            {toolOptions(settings, tools, m[name], name, idx, onChange, blankToolTitle)}
        </div>
    );
}

function toolOptions(settings, tools, selectedTool, name, idx, onChange, blankToolTitle = "") {
    let [style, title] = [{}, ""];

    if (!tools.includes(selectedTool)) {
        tools = selectedTool ? tools.concat(selectedTool) : [selectedTool].concat(tools);
        style = { backgroundColor: "red", color: "white" };
        title = selectedTool ? `${selectedTool} does not exist for this printer profile` : "No tool selected";
    }

    const toolTitle = tool => {
        return tool === "" ? blankToolTitle : settings.physicalTools.includes(tool) ? "Physical" : "Virtual";
    };

    return (
        <select
            name={name}
            className="combo"
            idx={idx}
            value={selectedTool}
            onChange={onChange}
            style={style}
            title={title}
        >
            {tools.map(t => (
                <option value={t} key={t} title={toolTitle(t)}>
                    {t}
                </option>
            ))}
        </select>
    );
}

function opt(val, text = null, key = null, title = "") {
    return { val, key, text, title };
}

function select(name, value, options, onChange, style = {}, title = "") {
    return (
        <select name={name} className="combo" value={value} onChange={onChange} style={style} title={title}>
            {options.map(o => (
                <option value={o.val} key={o.key !== null ? o.key : o.val} title={o.title}>
                    {o.text ? o.text : o.val}
                </option>
            ))}
        </select>
    );
}

function selectLabeled(label, name, value, options, onChange, classes, style = {}, title = "") {
    return (
        <div className={classes} title={title}>
            <label className="value-label vcenter">{label}</label>
            {select(name, value, options, onChange, style, title)}
        </div>
    );
}

function getT0PctFromWeights(weights) {
    return Math.round((100 * weights["T0"]) / (weights["T0"] + weights["T1"]));
}

export { range, rangeInputs, numberInput, toolSelect, toolOptions, opt, selectLabeled, getT0PctFromWeights };
