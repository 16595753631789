import { gcodeParse } from "../utils/gcodeParse";
import { round } from "../utils/utils";

export default class FileDetails {
    constructor(fileName, lines, isDemo = false) {
        this.fileName = fileName;
        this.lines = lines.map(l => l.trim());
        this.isDemo = isDemo;

        const startMs = new Date();
        this.obj = gcodeParse(this.lines);

        this.procMs = new Date() - startMs;
        this.LinesPerMs = round(lines.length / this.procMs, 5);
    }

    dump() {
        const obj = {
            error: this.obj.error,
            valid: this.obj.valid,
            warning: this.obj.warning,
            maxHeight: this.obj.maxHeight,
            l2h: this.obj.lineToHeightTree.entries(),
            l2t: this.obj.lineToToolTree.entries(),
            s2l: this.obj.segmentToLineMap
        };

        return JSON.stringify({ lines: this.lines.length, isDemo: this.isDemo, obj });
    }
}
