import React from "react";
export default function ButtonFileOpen({ fileDetails, readFile }) {
  const onOpen = ev => {
    ev.preventDefault();

    if (ev.target.files.length > 0) readFile(ev.target.files[0]);
  };

  const classes = "file-btn-div" + (fileDetails.fileName ? "" : " hue-rotate");

  return (
    <div className={classes} id="btn-open" key="btn-open" onChange={onOpen} title="Open a G-code file for processing">
      <label htmlFor="file-chooser">
        <img name="open" className="btn file-btn-img" src={require("../resources/btn-open.svg")} alt="Select File" />
      </label>
      <input type="file" id="file-chooser" accept=".gcode,.gco,.g,*.*" />
    </div>
  );
}

export { ButtonFileOpen };
