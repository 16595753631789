import React, { Component } from "react";
import ModPanel from "./ModPanel";
import { ButtonAdd } from "./ButtonAdd";

export default class ModPanels extends Component {
    render() {
        if (!this.props.fileDetails.fileName || this.props.config.mods.length === 0) return null;

        return (
            <React.Fragment>
                <div id="mods" className="panel flex-column" style={modPanelsStyle}>
                    {this.props.config.mods.map(mod => (
                        <ModPanel
                            key={mod.id}
                            config={this.props.config}
                            mod={mod}
                            fileDetails={this.props.fileDetails}
                            settings={this.props.settings}
                            meta={this.props.meta}
                            onChangeMod={this.props.onChangeMod}
                            onDeleteMod={this.props.onDeleteMod}
                            onReplaceMods={this.props.onReplaceMods}
                        />
                    ))}
                </div>
                <ButtonAdd
                    title="Add a modifier"
                    onClick={this.props.onAddMod}
                    style={{ width: "30px", height: "30px" }}
                />
            </React.Fragment>
        );
    }
}

const modPanelsStyle = {
    display: "block"
};
