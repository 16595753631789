import AVLTree from "avl"; // https://github.com/w8r/avl/blob/master/src/index.js

class MyAVLTree extends AVLTree {
  constructor(key = null, val = null) {
    super();
    if (key != null) this.insert(key, val);
  }

  /**
   * Find the largest node with a value less than or equal to the value given.
   * @param  {number} val - the value for the search
   */
  findFloor(val) {
    const node = walkFloor(this._root, val);
    return node ? node.data : null;
  }

  /**
   * Find the largest node with a value less than or equal to the value given.
   * @param  {number} val - the value for the search
   */
  findFloorNode(val) {
    return walkFloor(this._root, val);
  }

  /**
   * Find the smallest node with a value greater than or equal to the value given.
   * @param  {number} val - the value for the search
   */
  findCeiling(val) {
    const node = walkCeiling(this._root, val);
    return node ? node.data : null;
  }

  /**
   * Find the smallest node with a value greater than or equal to the value given.
   * @param  {number} val - the value for the search
   */
  findCeilingNode(val) {
    return walkCeiling(this._root, val);
  }

  /**
   * Remove the previous node if it exists and then insert the new one.
   */
  replace(key, val) {
    this.remove(key);
    this.insert(key, val);
  }

  replaceIfChanged(key, val, isSame = (v1, v2) => v1 === v2) {
    if (!isSame(this.findFloor(key), val)) this.replace(key, val);
  }

  /**
   * Next node
   */
  nextNode(node) {
    const next = this.next(node);
    return next !== node ? next : null;
  }

  copyFloor(start = null, end = null) {
    const newTree = new MyAVLTree();

    for (let n = start ? this.findFloorNode(start) : this.minNode(); n && n.key <= end; n = this.nextNode(n)) {
      newTree.insert(n.key, n.data);
    }

    return newTree;
  }

  copyCeiling(start = null, end = null) {
    const newTree = new MyAVLTree();

    for (let n = start ? this.findCeilingNode(start) : this.minNode(); n && n.key <= end; n = this.nextNode(n)) {
      newTree.insert(n.key, n.data);
    }

    return newTree;
  }

  entries() {
    const arr = [];
    for (let node = this.minNode(); node != null; node = this.nextNode(node)) {
      arr.push([node.key, node.data]);
    }

    return arr;
  }
}

function walkFloor(node, val) {
  if (node == null) return null;
  if (val === node.key) return node;
  if (val > node.key) return node.right ? walkFloor(node.right, val) || node : node;
  return node.left ? walkFloor(node.left, val) : null;
}

function walkCeiling(node, val) {
  if (node == null) return null;
  if (val === node.key) return node;
  if (val > node.key) return node.right ? walkCeiling(node.right, val) : null;
  return node.left ? walkCeiling(node.left, val) || node : node;
}

export { MyAVLTree };
