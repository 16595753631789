import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { getFilamentChangeCmds, getMixResetCmds, isNumber } from "../../utils/utils";

export default class ModFilamentChange extends Mod {
    fillMeta(settings, fd, meta) {
        if (!isNumber(this.filamentChangeHeight)) return;

        const colorMap = meta.heightToColorMapTree.findFloor(this.filamentChangeHeight);
        meta.heightToColorMapTree.replace(this.filamentChangeHeight, {
            ...colorMap,
            [this.filamentChangeTool]: this.filamentChangeColor
        });

        const lineNum = fd.obj.heightToLineTree.findCeiling(this.filamentChangeHeight);
        if (!lineNum) return;

        let cmds = settings.isSingleExtruder
            ? ["; Filament change"]
            : ["; Filament change for " + this.filamentChangeTool];
        cmds = cmds.concat(getMixResetCmds(settings, this.filamentChangeTool));
        cmds = cmds.concat(getFilamentChangeCmds(settings, this.filamentChangeTool));

        meta.newCommands.add(lineNum, new Commands(this, this.filamentChangeHeight, cmds));
    }
}
