import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { boomOdd, getMixResetCmds } from "../../utils/utils";
import { mixColorsByWeight, rgbToHexString, endColorMap, colorMapToRgbMap } from "../../utils/colors";

export default class ModGradient extends Mod {
  getError(settings) {
    if (settings.toolCnt <= 2) return ""; // Uses % instead

    for (let i = 0; i < settings.toolCnt; i++) {
      if (this.gradientWeights[i] && this.weightSum(settings, this.gradientWeights[i]) === 0) {
        return `MIX ${i + 1} FOR MODIFIER ${this.id} MUST HAVE AT LEAST ONE TOOL WITH A NON-ZERO WEIGHT`;
      }
    }

    return "";
  }

  getStartTool(meta) {
    return this.mixTool;
  }

  weightSum(settings, weights) {
    return settings.physicalTools.reduce((sum, t) => (weights[t] ? sum + (weights[t] || 0) : sum), 0);
  }

  fillMeta(settings, fileDetails, meta) {
    if (!(this.gradientLevels > 0)) return; // Prevent an exception if gradientLevels is ""

    this.startCommands(fileDetails, meta); // Set the current tool to the gradient tool if not leaving tool changes

    let prevLine = null;
    const obj = fileDetails.obj;
    const endHeight = Math.min(this.endHeight, obj.maxHeight + 0.00001);
    const origColorTree = meta.heightToColorMapTree.copyFloor(this.startHeight, endHeight);
    const totalHeight = endHeight - this.startHeight;
    if (totalHeight <= 0) return;

    const heightPerGradient = totalHeight / this.gradientRepeatCount;

    // Remove any nodes that conflict with this modifier
    let node = origColorTree.findCeilingNode(this.startHeight);
    for (; node && node.key < this.endHeight; node = origColorTree.nextNode(node)) {
      if (node > this.startHeight) meta.heightToColorMapTree.remove(node.key);
    }

    for (let i = 0; i < this.gradientRepeatCount; i++) {
      const levels = boomOdd(this, this.gradientLevels); // Needs to be odd if boomerang so that there is one middle level
      const heightPerLevel = heightPerGradient / levels;

      for (let j = 0; j < levels; j++) {
        const height = this.startHeight + i * heightPerGradient + heightPerLevel * j;
        const colorNode = origColorTree.findFloorNode(height); // The original color map at this height
        const colorMap = colorNode.data;

        let mixProg = j / (levels - 1);
        if (this.boomerang) mixProg = mixProg <= 0.5 ? mixProg * 2 : 2 - mixProg * 2;

        // Ranges exist between mixes, so if there are 3 mixes, then there are 2 ranges with indexes 0 and 1
        const { rangeIdx, rangeProg } = this.getRangeParams(settings, mixProg);

        // Check if there is a filament change between this height and the next height and apply the mix to it
        const nextHeight = height + heightPerLevel;
        this.processMidLevelColorChange(settings, obj, origColorTree, colorNode, nextHeight, rangeIdx, rangeProg, meta);

        const curLine = obj.heightToLineTree.findCeiling(height);
        if (curLine === prevLine) continue; // Avoid doing multiple changes on the same line

        prevLine = curLine;

        const rgb = this.getColorByRangeParams(settings, colorMap, rangeIdx, rangeProg);
        meta.heightToColorMapTree.replace(height, { ...colorMap, [this.mixTool]: rgbToHexString(rgb) });

        const cmds = this.getMixCmds(settings, rangeIdx, rangeProg, meta, curLine);
        meta.newCommands.add(curLine, new Commands(this, height, cmds));
      }
    }

    this.endCommands(fileDetails, settings, meta); // End commands to reset the mix

    endColorMap(origColorTree, fileDetails, meta, endHeight); // Put the color map back afterwards
  }

  startCommands(fileDetails, meta) {
    if (this.overlap) return;

    const startLine = fileDetails.obj.heightToLineTree.findCeiling(this.startHeight);
    meta.lineToToolTree.replaceIfChanged(startLine, this.mixTool);
    meta.newCommands.add(startLine, new Commands(this, this.startHeight, [this.mixTool.toString()]));
  }

  endCommands(fileDetails, settings, meta) {
    const endLine = fileDetails.obj.heightToLineTree.findCeiling(this.endHeight) || fileDetails.lines.length;
    let endCmds = getMixResetCmds(settings, this.mixTool);
    endCmds = this.appendToolIfOverlapping(meta, endLine, endCmds);

    meta.newCommands.add(endLine, new Commands(this, this.endHeight, endCmds));
  }

  getRangeParams(settings, mixProg) {
    if (settings.toolCnt === 2) return { rangeIdx: 0, rangeProg: mixProg };

    const ranges = this.gradientWeights.length - 1;
    const pctPerRange = 1 / ranges;
    const rangeIdx = Math.max(0, Math.ceil(mixProg / pctPerRange) - 1);
    const rangeProg = (mixProg - rangeIdx * pctPerRange) * ranges;

    return { rangeIdx, rangeProg };
  }

  getColorByRangeParams(settings, colorMap, rangeIdx, rangeProg) {
    const rgbMap = colorMapToRgbMap(colorMap, settings.tools);
    const w = this.rangeProgToWeights(settings, rangeIdx, rangeProg);

    return mixColorsByWeight(settings.physicalTools, rgbMap, w);
  }

  rangeProgToWeights(settings, rangeIdx, rangeProg) {
    return settings.physicalTools.reduce((map, t) => {
      return { ...map, [t]: this.rangeProgToWeight(t, rangeIdx, rangeProg) };
    }, {});
  }

  rangeProgToWeight(t, rangeIdx, rangeProg) {
    const start = this.gradientWeights[rangeIdx][t] || 0;
    const end = this.gradientWeights[rangeIdx + 1][t] || 0;

    return start + rangeProg * (end - start);
  }

  getMixCmds(settings, rangeIdx, rangeProg, meta, curLine) {
    let cmds = settings.physicalTools
      .map((t, i) => `M163 S${i} P${this.getWeight(t, rangeIdx, rangeProg)}`)
      .concat(`M164 S${this.mixTool.slice(1)}`);

    return this.appendToolIfOverlapping(meta, curLine, cmds);
  }

  // If leaving changes, we need to put the tool back after M164 changes it
  // Otherwise, the active tool should be the tool for the mix
  appendToolIfOverlapping(meta, curLine, cmds) {
    if (this.overlap) {
      const curTool = meta.lineToToolTree.findFloor(curLine);
      if (curTool !== this.mixTool) cmds = cmds.concat(curTool);
    }

    return cmds;
  }

  getWeight(tool, rangeIdx, rangeProg) {
    const startWeight = this.gradientWeights[rangeIdx][tool] || 0;
    const endWeight = this.gradientWeights[rangeIdx + 1][tool] || 0;
    const diff = endWeight - startWeight;

    return Math.round((startWeight + diff * rangeProg) * 100, 2) / 100;
  }

  processMidLevelColorChange(settings, obj, origColorMapTree, colorMapNode, nextHeight, rangeIdx, rangeProg, meta) {
    const nextNode = origColorMapTree.nextNode(colorMapNode);
    if (!nextNode || nextNode.key >= nextHeight) return; // No in range color change to deal with

    const rgb = this.getColorByRangeParams(settings, nextNode.data, rangeIdx, rangeProg);
    meta.heightToColorMapTree.replace(nextNode.key, { ...nextNode.data, [this.mixTool]: rgbToHexString(rgb) });

    const lineNum = obj.heightToLineTree.findCeiling(nextNode.key);
    const cmds = new Commands(this, nextNode.key, this.getMixCmds(settings, rangeIdx, rangeProg, meta, lineNum));
    meta.newCommands.add(lineNum, cmds);
  }
}
