export class Pattern {
    constructor(tool, height = 2.0, endHeight = 2.0, boomerang = false) {
        this.tool = tool;
        this.height = height;
        this.endHeight = endHeight;
        this.boomerang = boomerang;
    }

    copy() {
        return new Pattern(this.tool, this.height, this.endHeight, this.boomerang);
    }
}
