import React from "react";
import { numberInput } from "./ComponentCommon";
import { SliderLabelled } from "./SliderLabelled";

const HEIGHT_STEP_TIP =
    "This configures in mm how often the custom G-code will be inserted.\n" +
    "If this is set to 10, then the custom G-code will be inserted every 10mm within the start and end height.";
const CUSTOM_TEXT_TIP =
    "- This G-code script will be inserted at each height step within the start and end height\n" +
    "- The variable will increase by the variable step size at each height step\n" +
    "- Use at your own risk and verify that the resulting G-code changes are as intended\n" +
    "- Some uses are to change the temperature, to insert a pause, or to change what is displayed on the LCD\n" +
    "- Use format specifiers to indicate replacements\n" +
    "  %v : the variable value rounded to 3 decimal places\n" +
    "  %V : the variable value rounded to an integer\n" +
    "  %h : the height rounded to 3 decimal places\n" +
    "  %H : the height rounded to an integer\n" +
    "  %t : the active tool number without 'T' in front of it\n" +
    "  %T : the active tool\n";

export default function ModPanelCustomGcode({ fileDetails, m, onChangeValue, onChangeField }) {
    const gcodeText = () => {
        const textAreaStyle = { width: "60ch", minWidth: "20ch", maxWidth: "calc(100% - 10px)", minHeight: "4ch" };

        return (
            <div className="panel-contents" title={CUSTOM_TEXT_TIP}>
                <div className="panel-item" style={{ maxWidth: "100%", padding: 5 }}>
                    <textarea
                        name={m.customGcodeText}
                        value={m.customGcodeText}
                        rows={m.customGcodeText.split("\n").length + 2}
                        style={textAreaStyle}
                        placeholder={"; This G-code will be inserted at each height step"}
                        onChange={ev => onChangeField("customGcodeText", ev.target.value)}
                    ></textarea>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="panel-contents">
                <SliderLabelled
                    label="Height Step Size"
                    title={HEIGHT_STEP_TIP}
                    name="customGcodeHeightStep"
                    val={m.customGcodeHeightStep}
                    min="0.1"
                    max={fileDetails.obj.maxHeight}
                    step="0.1"
                    idx=""
                    onChange={onChangeValue}
                />
                {numberInput(
                    "Variable initial value",
                    "customGcodeVarStart",
                    m.customGcodeVarStart,
                    -10000,
                    10000,
                    "",
                    ev => onChangeField("customGcodeVarStart", ev.target.value),
                    0.001
                )}
                {numberInput(
                    "Variable step size",
                    "customGcodeVarStep",
                    m.customGcodeVarStep,
                    -10000,
                    10000,
                    "",
                    ev => onChangeField("customGcodeVarStep", ev.target.value),
                    0.001
                )}
            </div>
            {gcodeText()}
        </React.Fragment>
    );
}
