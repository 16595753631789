import React, { useState, useEffect } from "react";
import { numToWidth, isNumber } from "../utils/utils";

export default function Slider({
  name,
  val,
  min,
  max,
  idx,
  onChange,
  rangeClasses = "",
  rangeStyle = {},
  numStyle = {},
  numberFirst = false,
  step = 1,
}) {
  const [pendingTarget, setPendingTarget] = useState(null);
  const roundedMax = Math.ceil(max / step) * step;
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [pendingTarget, timer]);

  const clearPending = (target = null) => {
    clearTimeout(timer);
    setTimer(null);
    setPendingTarget(target);
  };

  const onChangeNumber = ev => {
    if (ev.nativeEvent.inputType !== "insertText") return onChange(ev);

    const t = ev.target;
    const target = {
      id: t.id,
      name: t.name,
      min: t.min,
      max: t.max,
      value: Number(t.value),
      step: t.step,
      type: t.type,
      getAttribute: () => t.getAttribute("idx"),
    };

    clearPending(target);

    const tmr = setTimeout(() => commitPendingTarget(target), 5000);
    setTimer(tmr);
  };

  const onKeyDown = ev => {
    if (ev.keyCode === 13 && pendingTarget !== null) {
      clearPending();
      onChange({ target: pendingTarget });
    }
  };

  const commitPendingTarget = target => {
    if (isNumber(target.value)) onChange({ target });
    clearPending();
  };

  // If the range is changed with a pending number typed in, just go with the new range value
  const onChangeRange = ev => {
    clearPending();
    onChange(ev);
  };

  const inputs = () => {
    const decimals = step >= 1 ? 0 : step === 0.1 ? 1 : 2;
    const v = pendingTarget !== null ? pendingTarget.value : val;
    const vStr = decimals > 0 && isNumber(v) ? v.toFixed(decimals) : v.toString();
    const valAttr = pendingTarget !== null ? { defaultValue: vStr } : { value: vStr };
    let numberStyle = { width: numToWidth(max, decimals), ...numStyle };
    if (!isNumber(v)) numberStyle = { ...numberStyle, backgroundColor: "red", color: "white" };

    const rangeInput = (
      <input
        type="range"
        name={name}
        value={vStr}
        min={min}
        max={max}
        step={step}
        idx={idx}
        style={rangeStyle}
        className={rangeClasses}
        onChange={onChangeRange}
      />
    );
    const numberInput = (
      <input
        type="number"
        name={name}
        {...valAttr}
        min={min}
        max={roundedMax}
        step={step}
        idx={idx}
        style={numberStyle}
        onChange={onChangeNumber}
        onKeyDown={onKeyDown}
      />
    );

    return numberFirst ? (
      <React.Fragment>
        {numberInput} {rangeInput}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {rangeInput} {numberInput}
      </React.Fragment>
    );
  };

  return inputs();
}

export { Slider };
