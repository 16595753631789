import React from "react";
import { range } from "./ComponentCommon";
import { getMaxLevelsByStep } from "../utils/utils";
import YesNoItem from "./YesNoItem";

const BOOM_TIP = "If enabled, the random pattern will be symmetric from bottom to top";
const SEED_TIP =
    "Each seed value will produce a unique random sequence which can be reproduced using the same seed later";

export default function ModPanelRandoTool({ settings, fileDetails, meta, m, onChangeValue, onChangeRandoTool }) {
    const toolOptions = tools => {
        return <div className="panel-contents">{tools.map(t => toolOption(t))}</div>;
    };

    const toolOption = t => {
        // If it's not in randoTools, then is assumed to be a YES
        const val = m.randoTools[t] === undefined || m.randoTools[t];

        return (
            <div className="panel-item" key={t}>
                {t}
                <select
                    name="randoTools"
                    className="combo"
                    value={val}
                    onChange={ev => onChangeRandoTool(t, ev.target.value !== "false")}
                >
                    <option value={false}>NO</option>
                    <option value={true}>YES</option>
                </select>
            </div>
        );
    };

    const maxLevels = getMaxLevelsByStep(settings, fileDetails);

    return (
        <React.Fragment>
            <div className="panel-contents">
                <YesNoItem
                    label="Boomerang"
                    name="boomerang"
                    value={m.boomerang}
                    onChange={onChangeValue}
                    title={BOOM_TIP}
                />
                {range("Levels", "randoLevels", m.randoLevels, 2, maxLevels, "", onChangeValue)}
                {range("Seed", "randoSeed", m.randoSeed, 0, 1000, SEED_TIP, onChangeValue)}
            </div>
            {settings.toolCnt !== 2 ? toolOptions(meta.tools) : null}
        </React.Fragment>
    );
}
