import React, { useState, useEffect } from "react";
import { Slider } from "./Slider";

const SLIDER_TIP = "Adjust the bottom and top heights to select the viewable height range for the preview";

export function PreviewSliders({ obj, s1, s2, getWidth, onChangeSliders }) {
  const [scale, setScale] = useState(Math.min(1, getWidth() / 400));

  useEffect(() => {
    const handleResize = () => setScale(Math.min(1, getWidth() / 400));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getWidth]);

  const sliderStyle1 = {
    position: "absolute",
    left: 0,
    top: 2,
    marginLeft: 1,
    transform: `scale(${scale})`,
    transformOrigin: "0 0",
  };
  const sliderStyle2 = { ...sliderStyle1, top: sliderStyle1.top + 20 };
  const numStyle = { backgroundColor: "#aaa8", border: "none", height: 16, marginLeft: 5 };
  const node = obj.heightToLineTree.findFloorNode(obj.maxHeight - 0.000001);
  const maxHeight = node ? node.key : obj.maxHeight;
  const props = {
    min: "0",
    max: maxHeight,
    step: 0.01,
    numStyle: numStyle,
    numberFirst: true,
    rangeClasses: "small-margin",
    onChange: onChangeSliders,
  };

  return (
    <React.Fragment>
      <div className="flex-row" style={sliderStyle1} title={SLIDER_TIP}>
        <Slider name="s1" val={s1} {...props} />
      </div>
      <div className="flex-row" style={sliderStyle2} title={SLIDER_TIP}>
        <Slider name="s2" val={s2} {...props} />
      </div>
    </React.Fragment>
  );
}
