import React from "react";
import cogoToast from "cogo-toast";

const cogoMsgMap = msg => {
    if (!msg.includes || !msg.includes("\n")) return msg;

    const smallWindow = document.documentElement.clientWidth < 500;

    return (
        <div>
            {msg.split("\n").map((l, i) => (
                <div style={{ whiteSpace: smallWindow ? "pre-wrap" : "pre", lineHeight: "1.5" }} key={i}>
                    {l}
                </div>
            ))}
        </div>
    );
};

const cogoMsg = (type, msg, options = {}) => {
    msg = cogoMsgMap(msg);
    const { hide } = cogoToast[type](msg, {
        position: "bottom-center",
        hideAfter: 10,
        onClick: () => hide(),
        ...options
    });
};

const cogoSuccess = (msg, options = {}) => cogoMsg("success", msg, options);
const cogoInfo = (msg, options = {}) => cogoMsg("info", msg, options);
const cogoLoading = (msg, options = {}) => cogoMsg("loading", msg, options);
const cogoWarn = (msg, options = {}) => cogoMsg("warn", msg, options);
const cogoError = (msg, options = {}) => cogoMsg("error", msg, options);

export { cogoError, cogoInfo, cogoLoading, cogoSuccess, cogoWarn };
