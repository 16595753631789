import { MyAVLTree } from "./MyAVLTree";
import { ArrayDict } from "./ArrayDict";
import { Commands } from "./Commands";
import { gaAssert } from "../utils/ga";
import { getMixResetCmds } from "../utils/utils";

export default class Metadata {
    constructor(settings, fileDetails, config) {
        this.heightToColorMapTree = new MyAVLTree(-1, settings.colorMap);
        this.lineToToolTree = new MyAVLTree(1, "T0");
        this.newCommands = new ArrayDict();
        this.tools = settings.physicalTools.concat(config.virtualMixTools);
        this.toolsWithBlank = ["", ...this.tools];
        this.error = config.getError(settings, fileDetails);
        this.config = config;

        const obj = fileDetails.obj;

        if (!fileDetails.fileName || !obj.valid) return;

        const blockMods = config.blockMods.filter(m => !m.hasZeroHeight());
        if (blockMods.length > 0) {
            const cmds = this.getInitialMixes(settings);
            this.newCommands.add(1, new Commands(config.mods[0], 0, cmds));
        }

        // Update the color map tree for the filament changes first
        for (let m of config.nonBlockMods) {
            m.fillMeta(settings, fileDetails, this);
        }

        this.copyToolTreeGaps(obj, blockMods);

        for (let m of blockMods) {
            m.fillMeta(settings, fileDetails, this);

            this.insertEndTool(settings, obj, m, fileDetails);

            // If this modifier is at the initial layer, replace the initial lineToTool mapping
            if (m.startHeight === 0 && !m.overlaps()) this.lineToToolTree.replace(1, m.getStartTool(this));
        }

        // Need to make sure that the right tool is active after filament changes
        for (let m of config.filamentChangeMods) {
            const lineNum = obj.heightToLineTree.findCeiling(m.filamentChangeHeight);
            const cmds = this.newCommands[lineNum].filter(c => c.m === m)[0];
            const tool = this.lineToToolTree.findFloor(lineNum);
            if (tool !== m.filamentChangeTool) cmds.cmds.push(tool + " ; Put the tool back");
        }
    }

    getInitialMixes(settings) {
        let cmds = [];

        settings.physicalTools.forEach(t => {
            cmds = [...cmds, ...getMixResetCmds(settings, t)];
        });

        return cmds;
    }

    insertEndTool(settings, obj, m, fileDetails) {
        // Don't bother doing a filament change at the end of the print
        if (settings.isSingleExtruder && m.endHeight >= fileDetails.obj.maxHeight) return;

        const lineNum = obj.heightToLineTree.findCeiling(m.endHeight);
        const prevTool = this.lineToToolTree.findFloor(lineNum);
        const [endTool, comment] = m.endTool
            ? [m.endTool, " ; End tool"]
            : [obj.lineToToolTree.findFloor(lineNum), " ; Put the original tool back"];
        this.updateToolAtHeight(fileDetails, m.endHeight, endTool);

        const cmds = m.getToolChangeCmds(settings, endTool, prevTool, m.endHeight, comment);

        this.newCommands.add(lineNum, new Commands(m, m.endHeight, cmds));
    }

    // Go through and copy all of the ranges that are NOT overwritten by modifiers
    copyToolTreeGaps(obj, mods) {
        let height = 0;

        for (let m of mods) {
            // Copy the range up to the current modifier
            if (height < m.startHeight) this.copyToolTreeRange(height, m.startHeight, obj);

            if (!m.overlaps()) {
                // If this modifier is at the initial layer, replace the initial lineToTool mapping
                if (m.startHeight === 0) this.lineToToolTree.replace(1, m.getStartTool(this));

                height = m.endHeight;
            }
        }

        // If there is space above all of the modifiers, then copy the remaining tree
        this.copyToolTreeRange(height, obj.maxHeight, obj);
    }

    copyToolTreeRange(startHeight, endHeight, obj) {
        if (startHeight > endHeight) return;

        const ltt = obj.lineToToolTree;
        const startLine = obj.heightToLineTree.findCeiling(startHeight); // First line at or past that height
        const endLine = obj.heightToLineTree.findCeiling(endHeight); // First line at or past that height

        for (let node = ltt.findCeilingNode(startLine); node && node.key < endLine; node = ltt.nextNode(node)) {
            this.lineToToolTree.replaceIfChanged(node.key, node.data);
        }
    }

    lineToColor(obj, lineNum, height) {
        if (this.error) return "#FFFFFF";

        const colorMap = this.heightToColorMapTree.findFloor(height);
        const tool = this.lineToToolTree.findFloor(lineNum);

        if (!colorMap || !colorMap[tool])
            gaAssert("lineToColor", {
                height,
                lineNum,
                lines: obj.lines?.length,
                tool,
                error: this.error,
                colorMaps: JSON.stringify(this.heightToColorMapTree.entries()),
                l2t: JSON.stringify(this.lineToToolTree.entries()),
                h2l: JSON.stringify(obj.heightToLineTree.entries()),
                config: JSON.stringify(this.config)
            });

        return colorMap ? colorMap[tool] : "#FFFFFF";
    }

    updateToolAtHeight(fileDetails, height, tool) {
        const lineNum = fileDetails.obj.heightToLineTree.findCeiling(height);
        this.lineToToolTree.replaceIfChanged(lineNum, tool);
    }
}
