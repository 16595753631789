/**
 * Based on the GCodeLoader.js file from the three.js gcode demo from the authors below
 *
 * @param {Manager} manager Loading manager.
 * @author tentone
 * @author joewalnes
 */

import { BufferGeometry, Euler, Float32BufferAttribute, Group, LineBasicMaterial, LineSegments } from "three";

import { DBG } from "../utils/globals";
import { MyAVLTree } from "../classes/MyAVLTree";
import { gaAssert } from "./ga";

const INVALID_LAYER_HEIGHT = 1000000;
const LAYER_HEIGHT_INVALID_THRESHOLD = 5;

function gcodeParse(lines) {
    let state = { x: 0, y: 0, z: 0, h: 0, e: 0, f: 0, extruding: false, relative: false, extrusionRelative: false };
    let groups = [];
    let curGroup = undefined;
    let currentTool = "";
    let prevZ = null;
    let firstLayerHeight = INVALID_LAYER_HEIGHT;
    let maxLayerHeight = 0;
    let maxHeight = 0;
    let firstCmdLine = 0;
    let lastExtrudeLine = 0;
    let error = "";
    let warning = "";
    let firstM164line = null;
    const lineToToolTree = new MyAVLTree(1, "T0");
    const l2h = new MyAVLTree(1, 0);
    const h2l = new MyAVLTree();
    const zTree = new MyAVLTree(0, 0);
    const layerThreshold = 0.1;

    for (var i = 0; i < lines.length; i++) {
        const tokens = lines[i].replace(/;.*/g, "").trim().split(" "); // This is faster than split(";")[0] if there is no ';' present
        const cmd = tokens[0].trim().toUpperCase();
        const lineNum = i + 1;

        if (cmd === "G0" || cmd === "G1") {
            var line = tokens2Line(tokens);

            // Layer change detection is made by watching Z, it's made by watching when we extrude at a new Z position
            if (line.e > state.e) {
                line.extruding = true;
                lastExtrudeLine = lineNum;
                if (firstCmdLine === 0) firstCmdLine = lineNum;

                // if the z values are .2 and .3, the result is .9999999998 instead of .1 which fails for layer threshold of .1
                if (curGroup === undefined || Math.abs(line.z - curGroup.z) >= layerThreshold - 0.00001)
                    newGroup(line, lineNum);

                addSegment(state, line, lineNum);
            }

            state = line;
        } else if (cmd === "G2" || cmd === "G3") {
            //G2/G3 - Arc Movement ( G2 clock wise and G3 counter clock wise )
            //console.warn("THREE.GCodeLoader: Arc command not supported");
            warning = "G2/G3 G-CODE COMMANDS EXIST WHICH MAY CAUSE THE PREVIEW TO DISPLAY INCORRECTLY BUT SHOULD NOT AFFECT THE PRINT";
        } else if (cmd === "G90") {
            //G90: Set to Absolute Positioning
            state.relative = false;
            state.extrusionRelative = false;
        } else if (cmd === "G91") {
            //G91: Set to state.relative Positioning
            state.relative = true;
            state.extrusionRelative = true;
        } else if (cmd === "M82") {
            state.extrusionRelative = false;
        } else if (cmd === "M83") {
            state.extrusionRelative = true;
        } else if (cmd === "G92") {
            //G92: Set Position
            const args = getArgs(tokens);
            line = state;
            line.x = args.x !== undefined ? args.x : line.x;
            line.y = args.y !== undefined ? args.y : line.y;
            line.z = args.z !== undefined ? args.z : line.z;
            line.e = args.e !== undefined ? args.e : line.e;
            state = line;
        } else if (cmd[0] === "T" && cmd !== currentTool) {
            currentTool = cmd;

            if (line) {
                newGroup(line, lineNum); // Make sure that lines in segments have the same tool
                lineToToolTree.insert(lineNum, currentTool);
            }
        } else if (cmd === "M164") {
            if (firstCmdLine > 0 && firstM164line === null) firstM164line = lineNum;
        }
    }

    var object = new Group();
    object.name = "gcode";

    const segmentToLineMap = {};

    // This removes groups that are empty
    const filteredGroups = groups.filter(g => g.vertex.length > 0);
    for (let [i, g] of filteredGroups.entries()) {
        addGeometry(g.vertex, new LineBasicMaterial({ color: 0xdddddd, transparent: true }));
        segmentToLineMap[i] = g.lineNum;
    }

    h2l.replace(0, firstCmdLine);

    // Make sure that findCeiling works for height at or above the maxHeight
    h2l.replace(maxHeight + 100, lastExtrudeLine + 1);

    l2h.replace(lastExtrudeLine + 1, maxHeight);

    object.quaternion.setFromEuler(new Euler(-Math.PI / 2, 0, 0));

    object.firstCmdLine = firstCmdLine;
    object.maxHeight = round(maxHeight);
    object.firstLayerHeight = round(firstLayerHeight);
    object.maxLayerHeight = round(maxLayerHeight);
    object.lineToToolTree = lineToToolTree;
    object.lineToHeightTree = l2h;
    object.heightToLineTree = h2l;
    object.segmentToLineMap = segmentToLineMap;
    object.valid = firstLayerHeight < INVALID_LAYER_HEIGHT && maxLayerHeight > 0 && maxHeight > 0;
    object.error = error;
    object.warning = warning;

    if (DBG) getLayerHeightHistogram(object, filteredGroups, groups);

    return object;

    // Functions
    function tokens2Line(tokens) {
        const args = getArgs(tokens);

        var line = {
            x: args.x !== undefined ? absolute(state.x, args.x, state.relative) : state.x,
            y: args.y !== undefined ? absolute(state.y, args.y, state.relative) : state.y,
            z: args.z !== undefined ? absolute(state.z, args.z, state.relative) : state.z,
            e: args.e !== undefined ? absolute(state.e, args.e, state.extrusionRelative) : state.e,
            f: args.f !== undefined ? absolute(state.f, args.f, state.relative) : state.f,
        };
        line.h = z2h(line.z);
        return line;
    }

    function getArgs(tokens) {
        function getWarning(param) {
            if (warning) return warning;

            const lineInfo = `Line ${i + 1} failed to process ("${lines[i]}"). `;

            if (["X", "Y", "Z", "E", "F"].includes(param.toUpperCase()))
                return `${lineInfo}'${param}' should be followed by a number with no space in between.`;

            return `${lineInfo}'${param}' is not a valid parameter. Check if your custom g-code is missing a semicolon before a comment.`;
        }

        return tokens.splice(1).reduce(function (obj, t) {
            if (t[0] === undefined) return obj;

            const val = parseFloat(t.substring(1));
            if (Number.isNaN(val)) {
                warning = getWarning(t[0]);
                gaAssert("getArgs", { cmd: lines[i] });
            } else {
                obj[t[0].toLowerCase()] = val;
            }

            return obj;
        }, {});
    }

    function newGroup(line, lineNum) {
        curGroup = { vertex: [], z: line.z, h: line.h, lineNum };
        groups.push(curGroup);
        updateTrees(lineNum);
    }

    // Create line segment between p1 and p2
    function addSegment(p1, p2, lineNum) {
        if (curGroup === undefined) newGroup(p1, lineNum);

        curGroup.vertex.push(p1.x, p1.y, p1.h);
        curGroup.vertex.push(p2.x, p2.y, p2.h);
    }

    function updateTrees(lineNum) {
        const layerHeight = line.z - prevZ;
        if (layerHeight === 0) return;

        zTree.replace(line.z, line.z);

        prevZ = line.z;

        if (layerHeight > LAYER_HEIGHT_INVALID_THRESHOLD) return; // Only update height details if the layer height is reasonable

        l2h.replace(lineNum, line.h);
        h2l.replace(line.h, lineNum);

        if (line.z > maxHeight) maxHeight = line.z;

        if (layerHeight < 0) return;

        if (line.z < firstLayerHeight) firstLayerHeight = line.z;
        if (layerHeight > maxLayerHeight) maxLayerHeight = layerHeight;
    }

    function z2h(z) {
        const h = zTree.findFloor(z - 0.00000001);
        if (h === null) return 0;

        return z - h > LAYER_HEIGHT_INVALID_THRESHOLD ? z : h;
    }

    function absolute(v1, v2, relative) {
        return relative ? v1 + v2 : v2;
    }

    function addGeometry(vertex, material) {
        var geometry = new BufferGeometry();
        geometry.setAttribute("position", new Float32BufferAttribute(vertex, 3));

        var segments = new LineSegments(geometry, material);
        segments.name = "segment" + i;

        object.add(segments);
    }
}

function getLayerHeightHistogram(object, filteredGroups) {
    object.groupToZ = filteredGroups.map(g => g.z);
    object.groupToLayerHeight = object.groupToZ.map((z, i) => round(z - (i === 0 ? 0 : object.groupToZ[i - 1]), 1000));
    object.layerHeightHist = object.groupToLayerHeight.reduce((hist, v) => {
        return { ...hist, [v]: hist[v] ? hist[v] + 1 : 1 };
    }, {});
}

function round(v, factor = 100) {
    return Math.round(factor * v) / factor;
}

export { gcodeParse };
