import { ModType } from "./mods/Mod";
import { defaultMod, copyMod } from "./mods/ModUtils";
import { getMaxEndHeight, stepRound, isNumber, getMaxLevelsByStep } from "../utils/utils";

export default class Config {
    constructor(settings = null, fileDetails = null, mods = [], modLock = true) {
        this.mods = mods.length > 0 ? mods.map(m => copyMod(m)) : [defaultMod(settings, 1, 0, null, fileDetails)];
        this.modLock = modLock;
        this.valid = fileDetails != null || mods.length > 0;
    }

    get simpleSummary() {
        return Object.values(ModType)
            .filter(t => this.mods.some(m => m.type === t))
            .join(" - ");
    }

    // Mods that cannot overlap as opposed to a filament change mods are considered block mods
    get blockMods() {
        return this.mods
            .filter(m => m.isBlockMod())
            .sort((a, b) => a.startHeight - b.startHeight || overlapBias(a) - overlapBias(b));

        // Process the overlap mods after so that they can activate the right tool after M164
        function overlapBias(m) {
            return m.overlaps() ? 1 : 0;
        }
    }

    get blockModsNonOverlap() {
        return this.blockMods.filter(m => !m.overlaps());
    }

    get nonBlockMods() {
        return this.mods.filter(m => !m.isBlockMod()).sort((a, b) => a.filamentChangeHeight - b.filamentChangeHeight);
    }

    get filamentChangeMods() {
        return this.mods
            .filter(m => m.type === ModType.FILAMENT_CHANGE && isNumber(m.filamentChangeHeight))
            .sort((a, b) => a.filamentChangeHeight - b.filamentChangeHeight);
    }

    get virtualMixMods() {
        return this.mods.filter(m => m.type === ModType.VIRTUAL_MIX);
    }

    get virtualMixTools() {
        return this.virtualMixMods.map(m => m.virtualMixTool).filter(t => t !== "");
    }

    get gradientMods() {
        return this.mods.filter(m => m.type === ModType.GRADIENT);
    }

    get randoMixMods() {
        return this.mods.filter(m => m.type === ModType.RANDO_MIX);
    }

    copy() {
        return new Config(null, null, this.mods, this.modLock);
    }

    getError(settings, fileDetails) {
        if (!this.valid) return "";

        if (!fileDetails.obj.valid)
            return "UNABLE TO FIND A G-CODE OBJECT IN THE FILE.\nARE YOU SURE THAT YOU LOADED A G-CODE FILE AND NOT AN STL FILE?";

        if (fileDetails.obj.error) return fileDetails.obj.error;

        for (let m of this.blockMods) {
            if (m.startHeight > m.endHeight)
                return `THE START HEIGHT FOR MODIFIER ${m.id} CAN'T BE GREATER THAN THE END HEIGHT`;

            if (m.startHeight > fileDetails.obj.maxHeight) continue;

            if (this.blockMods.some(x => x !== m && m.conflicts(x)))
                return `MODIFIERS CANNOT OVERLAP UNLESS "OVERLAP" IS ENABLED`;
        }

        for (let m of this.mods) {
            const error = m.getError(settings);
            if (error) return error;
        }

        const virtualMixMods = this.virtualMixMods.filter(m => settings.virtualTools.some(t => m.virtualMixTool === t));
        const mixMods = this.gradientMods.concat(this.randoMixMods);

        for (let m of virtualMixMods) {
            if (virtualMixMods.filter(m2 => m2.virtualMixTool === m.virtualMixTool).length > 1)
                return "VIRTUAL MIX MODIFIERS CANNOT USE THE SAME TOOL";

            if (mixMods.some(m2 => m2.mixTool === m.virtualMixTool))
                return `VIRTUAL MIX TOOL ${m.virtualMixTool} CANNOT BE USED AS A TOOL FOR ANOTHER MODIFIER`;
        }

        return "";
    }

    getWarning(settings, fileDetails) {
        if (!this.valid) return "";

        return fileDetails.obj.warning;
    }

    newFile(settings, oldFd, newFd) {
        const [obj1, obj2] = [oldFd.obj, newFd.obj];
        const oldMax = obj1 ? obj1.maxHeight : 0;
        const maxEndHeight = getMaxEndHeight(settings, newFd);
        const newMaxHeight = stepRound(obj2.maxHeight, settings.minStep);
        const oldMaxLevels = getMaxLevelsByStep(settings, oldFd);
        const newMaxLevels = getMaxLevelsByStep(settings, newFd);

        this.mods.forEach(m => {
            m.startHeight = Math.min(m.startHeight, newMaxHeight);
            m.filamentChangeHeight = Math.min(m.filamentChangeHeight || 0, newMaxHeight);
            m.endHeight = m.endHeight > oldMax || m.endHeight > obj2.maxHeight ? maxEndHeight : m.endHeight;

            m.gradientLevels =
                m.gradientLevels === oldMaxLevels ? newMaxLevels : Math.min(m.gradientLevels, newMaxLevels);
            m.randoLevels = m.randoLevels === oldMaxLevels ? newMaxLevels : Math.min(m.randoLevels, newMaxLevels);
        });
    }
}
