import { Mod } from "./Mod";
import { Commands } from "../Commands";

export default class ModStripes extends Mod {
  fillMeta(settings, fileDetails, meta) {
    const lineNum = fileDetails.obj.heightToLineTree.findCeiling(this.startHeight);
    if (lineNum === null) return;

    const prevTool = meta.lineToToolTree.findFloor(lineNum);

    meta.lineToToolTree.replaceIfChanged(lineNum, this.mixTool);

    meta.newCommands.add(
      lineNum,
      new Commands(this, this.startHeight, this.getToolChangeCmds(settings, this.mixTool, prevTool, this.startHeight))
    );
  }

  getStartTool(meta) {
    return this.mixTool;
  }
}
