import React from "react";

const RGB_WARNING =
  "Due to limitations with RYB color mixing, the color may actually be displayed wrong in some cases but that will not affect the final print.\n" +
  "An example is that red and yellow combined will display as brown.";
const COLOR_TIP =
  "Choose your tool colors.\n" +
  "Virtual colors cannot be changed here and will be based on the physical tool colors and the mix ratios.\n" +
  RGB_WARNING;
const VIRTUAL_MIX_TIP = "This color is set with a Virtual Mix modifier and can't be changed.\n" + RGB_WARNING;

export default function PreviewColors({ settings, config, meta, onChangeColor }) {
  const onChange = ev => {
    onChangeColor(ev.target.id, ev.target.value);
  };

  const getColors = (label, colorMap, tools, isVirtualMix) => {
    if (tools.length === 0) return null;

    return (
      <div className="color-group flex-column">
        <div className="color-label" style={{ fontWeight: "bold" }}>
          {label}
        </div>
        <div className="colors">{tools.map(t => colorCode(colorMap, t, isVirtualMix))}</div>
      </div>
    );
  };

  const colorCode = (colorMapWithMixes, tool, isVirtualMix) => {
    if (tool === "") return null;

    const id = `${tool}-color`;
    const title = isVirtualMix ? VIRTUAL_MIX_TIP : "";
    const val = isVirtualMix ? colorMapWithMixes[tool] : settings.colorMap[tool];
    const classes = isVirtualMix ? "tool-color" : "tool-color color-hover";

    return (
      <div key={tool}>
        <label htmlFor={id} className="color-label">
          {tool}
        </label>
        <input
          type="color"
          id={tool}
          name={id}
          className={classes}
          value={val}
          onChange={onChange}
          disabled={isVirtualMix}
          title={title}
        />
      </div>
    );
  };

  const colorMapWithMixes = meta.heightToColorMapTree.findFloor(0);
  const virtualTools = config.virtualMixTools;

  return (
    <div id="preview-colors" className="panel-item flex-column color-column" title={COLOR_TIP}>
      {getColors("Physical Colors", colorMapWithMixes, settings.physicalTools, false)}
      {getColors("Virtual Colors", colorMapWithMixes, virtualTools, true)}
    </div>
  );
}
