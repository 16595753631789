import React from "react";
import { ButtonOctoSend } from "./ButtonOctoSend";
import { ButtonFileOpen } from "./ButtonFileOpen";
import { ButtonFileSave } from "./ButtonFileSave";
import { cogoWarn, cogoInfo } from "../utils/cogo";

let saveCount = 0;

const WARN_DEMO =
    "The sample G-code file is for a specific printer and is not recommended for use with another machine.";
const WARN_RETRACTION = "Retraction is enabled in the settings.  This is only intended for Creality CR-X printers.";
const WARN_SINGLE_EXT =
    "For single extruder printers, color changes require manual filament changes by the user.\nFor this to work, ADVANCED_PAUSE_FEATURE must be enabled in the firmware.";

const INFO_ASK_POST_RESULTS =
    "If you are enjoying this tool, please consider posting pics of your cool prints online to spread the word!";

export default function FileButtons({ settings, config, fileDetails, meta, readFile, updateChangeLines }) {
    const saveWarning = () => {
        if (fileDetails.isDemo) cogoWarn(WARN_DEMO);
        if (settings.retractionEnabled) cogoWarn(WARN_RETRACTION);
        if (settings.isSingleExtruder) cogoWarn(WARN_SINGLE_EXT);

        if (saveCount++ === 0) cogoInfo(INFO_ASK_POST_RESULTS);
    };

    const props = { settings, fileDetails, config, meta, readFile, updateChangeLines, saveWarning };

    return (
        <div id="file-buttons">
            <ButtonFileOpen {...props} />
            <ButtonFileSave {...props} />
            <ButtonOctoSend {...props} />
        </div>
    );
}
