import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ModType } from "../classes/mods/Mod";
import { cogoInfo } from "../utils/cogo";
import { stepRound } from "../utils/utils";
import { PreviewSliders } from "./PreviewSliders";
import { gaAssert, gaEvt } from "../utils/ga";

var camera, scene, renderer, controls, segmentCnt; // TODO
var askedForFile = false;
const PREVIEW_DBG = false;
const DRAW_AXES = false;
const ASK_SEND =
  "For some reason, there was an error processing this file. Would you like to send the file to G-Code Mix Master for analysis?";

export default class PreviewAdvanced extends Component {
  constructor(props) {
    super(props);
    this.state = { s1: 0, s2: props.fileDetails.obj.maxHeight };
  }

  render() {
    return (
      <div ref={ref => (this.mount = ref)} className="panel-item" style={{ position: "relative" }}>
        <PreviewSliders
          obj={this.props.fileDetails.obj}
          s1={this.state.s1}
          s2={this.state.s2}
          getWidth={() => this.getDimensions()[0]}
          onChangeSliders={this.onChangeSliders}
        />
      </div>
    );
  }

  onChangeSliders = ev => {
    const newState = { ...this.state, [ev.target.name]: stepRound(Number(ev.target.value), 0.01) };
    if (newState.s1 > newState.s2) newState[ev.target.name === "s1" ? "s2" : "s1"] = newState[ev.target.name];

    this.setState(newState);
  };

  componentDidMount() {
    scene = new THREE.Scene();

    const objHeight = this.props.fileDetails.obj.maxHeight;
    const cameraHeight = Math.max(-15, objHeight / 2 + 20);
    const cameraDistance = Math.max(50, 1.5 * objHeight);
    const [width, height] = this.getDimensions();
    camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 10000);
    camera.position.set(0, cameraHeight, cameraDistance);
    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.setClearColor(0xdddddd);

    this.mount.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", animate);
    controls.screenSpacePanning = true;

    setObject(this.props.fileDetails, this.props.meta);

    animate();

    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (!renderer) return;

    const dim = this.getDimensions();
    renderer.setSize(...dim);

    camera.aspect = dim[0] / dim[1];
    camera.updateProjectionMatrix();

    animate();
  };

  componentWillUnmount() {
    if (controls !== null) controls.removeEventListener("change", animate);

    window.removeEventListener("resize", this.handleResize);

    if (scene !== null) {
      while (scene.children.length) {
        scene.remove(scene.children[0]);
      }
    }

    scene = camera = renderer = controls = segmentCnt = null; // Needed??
  }

  componentDidUpdate(prevProps) {
    this.handleResize();
    updateColors(this.props.fileDetails, this.props.meta, this.state); // This might not happen as it might get unmounted and mounted again if the details change
    animate();
  }

  getDimensions() {
    if (window.innerWidth > 1200) {
      // Compensate if STRIPES_ADVANCED is taking up more width - Sync this with App.scss if the width threshold changes
      const subtract =
        window.innerWidth >= 1700 && this.props.config.mods.some(m => m.type === ModType.STRIPES_ADVANCED) ? 900 : 750;

      const width = window.innerWidth - subtract;
      return [width, window.innerHeight - 235];
    }

    const width = Math.max(270, window.innerWidth - 220);
    return [width, (width * 1.8) / 3];
  }
}

function animate() {
  const startMs = new Date();
  renderer.render(scene, camera);
  if (PREVIEW_DBG)
    console.log(`animate : children=${scene.children.length} segments=${segmentCnt} ${new Date() - startMs}ms`);
}

function setObject(fileDetails, meta) {
  if (fileDetails.obj == null) return;

  const box = new THREE.Box3().setFromObject(fileDetails.obj);

  fileDetails.obj.position.set(-100, -20, 100);

  updateColors(fileDetails, meta, { s1: 0, s2: fileDetails.obj.maxHeight });

  scene.add(fileDetails.obj);

  addPlane(box, 3000, 3000);

  if (DRAW_AXES) addAxes();

  segmentCnt = fileDetails.obj.children.length;
}

function addPlane(box, w, l) {
  var geometry = new THREE.PlaneGeometry(w, l, 1);
  var material = new THREE.MeshBasicMaterial({
    color: 0x000000,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.15,
  });

  var plane = new THREE.Mesh(geometry, material);
  plane.rotation.x = Math.PI * -0.5;
  plane.position.y = box.min.y - 20;
  plane.position.x = 10;
  plane.position.z = -10;

  scene.add(plane);
}

function addAxes(length = 10000) {
  addAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(length, 0, 0), 0xff0000); // X
  addAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, -length), 0x00ff00); // Y
  addAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, length, 0), 0x0000ff); // Z
}

function addAxis(v1, v2, color) {
  const material = new THREE.LineBasicMaterial({ color: color });
  const geometry = new THREE.BufferGeometry().setFromPoints([v1, v2]);

  scene.add(new THREE.Line(geometry, material));
}

export function updateColors(fileDetails, meta, state) {
  if (!fileDetails.obj) return;

  for (let i = 0; i < fileDetails.obj.children.length; i++) {
    const child = fileDetails.obj.children[i];
    const arr = child.geometry.attributes.position.array;
    let height = Math.round(100 * arr[2]) / 100;
    if (isNaN(height) || height < 0) {
      gaAssert("updateColors", { i, height, val: arr[2], fd: fileDetails.dump() });
      askToSendFile();
    }

    child.material.opacity = height < state.s1 || height > state.s2 ? 0 : 1;

    const line = fileDetails.obj.segmentToLineMap[i];
    const color = meta.lineToColor(fileDetails.obj, line, height);
    child.material.color = new THREE.Color(color);
    child.material.needsUpdate = true;
  }

  function askToSendFile() {
    if (askedForFile) return;

    askedForFile = true;

    if (window.confirm(ASK_SEND)) {
      gaEvt("send_file", { fd: fileDetails.dump(), lines: fileDetails.lines });
      cogoInfo("Thank you for choosing to share your file to help improve this tool for everyone!");
    }
  }
}
