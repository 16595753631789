import React from "react";

export default function YesNoItem({ label, name, value, onChange, title, idx = "", classes = "panel-item" }) {
  return (
    <div className={classes} title={title}>
      {label}
      <select name={name} className="combo" value={value} idx={idx} onChange={onChange}>
        <option value={false}>NO</option>
        <option value={true}>YES</option>
      </select>
    </div>
  );
}
