import React, { useState, useEffect, useCallback } from "react";
import { cogoSuccess, cogoError, cogoWarn } from "../utils/cogo";
import { numToWidth, boolVal, textLink } from "../utils/utils";
import { ProfileDefaults } from "../classes/Settings";
import { gaEvt } from "../utils/ga";
import { octo } from "../utils/octoprint";

export default function SettingsModal({ settings, onChangeAppState }) {
    const [pending, setPending] = useState(() => settings.copy());
    const [octoTesting, setOctoTesting] = useState(false);

    const OUT_FORMAT_TIP =
        "Use format specifiers to indicate replacements\n" +
        "  %f : the filename without the extension\n" +
        "  %e : the file extension without the dot (.)\n" +
        "  %m : a brief description of the config in lowercase letters\n" +
        "  %M : a brief description of the config in uppercase letters";
    const FILAMENT_CHG_CMDS_TIP =
        "- These G-code commands will be inserted at the filament change height to trigger a filament change\n" +
        "- Use format specifiers to indicate replacements\n" +
        "  %T : the filament change tool (always will be T0 for single extruder printers)\n" +
        "  %t : the filament change tool number without 'T' in front of it\n" +
        "- The tool MUST be set in the M600 command using T<index> for Marlin 2.0+\n" +
        "- See the  FAQ for more information about filament changes\n" +
        "- Filament changes require ADVANCED_PAUSE_FEATURE to be enabled in the printer firmware";
    const FILAMENT_CHG_SYNTAX = "M600 [T<index>] [B<beeps>] [E<pos>] [L<pos>] [U<pos>] [X<pos>] [Y<pos>] [Z<pos>]";
    const FILAMENT_CHG_URL = "https://marlinfw.org/docs/gcode/M600.html";
    const FILAMENT_CHG_LINK = textLink(FILAMENT_CHG_SYNTAX, FILAMENT_CHG_URL);

    const PRECISION_TIP =
        "This precision will be used for configuring heights and will give finer control for shorter objects";

    const RETRACTION_ENABLED_TIP =
        "This is only needed for Creality CR-X printers.\n" +
        "Retraction will occur prior to the tool change and then a priming will occur after.";
    const RETRACTION_SPEED_TIP = "This will be used for retraction and priming";
    const PRIME_DISTANCE_TIP =
        "This seems to be handled automatically by the CR-X firmware.\nThis can cause blobs if non-zero.";

    const OCTO_HOST_TIP = `The hostname or IP address for the OctoPrint server NOT including the port number`;
    const OCTO_KEY_TIP = `Get this key from the OctoPrint API settings and verify that CORS is enabled there.`;
    const OCTO_FOLDER_TIP = `Enter a subfolder to upload the file to or leave this blank for the root folder`;

    const SINGLE_EXT_NOTE = "The Filament Change G-Code below will be used for tool changes";
    const SINGLE_EXT_REQ = "ADVANCED_PAUSE_FEATURE must be enabled in your printer's firmware";

    const smallWindow = document.documentElement.clientWidth < 600;
    const textWidth = smallWindow ? "20ch" : "95%";

    const onSave = () => {
        pending.save();

        if (reloadNeeded()) window.location.reload(false);

        onChangeAppState({ settingsModal: false }, pending);
    };

    const reloadNeeded = () => {
        return false; // return pending.active.minStep !== settings.active.minStep;
    };

    const onReset = () => {
        setPending(pending.copy().reset());
    };

    const onClose = useCallback(() => {
        onChangeAppState({ settingsModal: false });
    }, [onChangeAppState]);

    const onChangeText = ev => {
        updateProfile(ev.target.name, ev.target.value);
    };

    const onChangeNum = ev => {
        updateProfile(ev.target.name, Number(ev.target.value));
    };

    const onChangeBool = ev => {
        updateProfile(ev.target.name, boolVal(ev.target.value));
    };

    const updateProfile = (name, val) => {
        if (pending.profiles[0][name] === undefined) {
            // Top Level setting
            setPending(pending.copy({ [name]: Number(val) }));
            return;
        }

        const profiles = [...pending.profiles];
        profiles[pending.activeProfile][name] = val;
        const newPending = pending.copy({ profiles });
        setPending(newPending);
    };

    const onAddProfile = ev => {
        setPending(
            pending.copy({
                activeProfile: pending.profiles.length,
                profiles: [...pending.profiles, { ...ProfileDefaults }]
            })
        );
    };

    const onCopyProfile = ev => {
        setPending(
            pending.copy({
                activeProfile: pending.profiles.length,
                profiles: [...pending.profiles, { ...pending.active, name: pending.active.name + " Copy" }]
            })
        );
    };

    const onDeleteProfile = ev => {
        if (pending.profiles.length === 1) {
            setPending(pending.copy({ activeProfile: 0, profiles: [{ ...ProfileDefaults }] }));
            return;
        }

        const profiles = [...pending.profiles];
        profiles.splice(pending.activeProfile, 1);
        setPending(pending.copy({ activeProfile: 0, profiles }));
    };

    useEffect(() => {
        function onEscape(e) {
            if (e.keyCode === 27) onClose();
        }

        document.addEventListener("keyup", onEscape);

        gaEvt("settings_modal");

        return function cleanup() {
            document.removeEventListener("keyup", onEscape);
        };
    }, [onClose]);

    const simpleNum = (label, name, min, max, title = "", step = 1) => {
        return simpleInput(label, name, "number", min, numToWidth(max), onChangeNum, title, step);
    };

    const simpleText = (label, name, title = "", placeHolder = "") => {
        return simpleInput(label, name, "text", null, textWidth, onChangeText, title, placeHolder);
    };

    const simpleInput = (label, name, type, min, width, onChange, title = "", placeHolder = "", step = 1) => {
        const val = pending.active[name] !== undefined ? pending.active[name] : pending[name];

        return (
            <tr title={title}>
                <td>{label}</td>
                <td>
                    <input
                        name={name}
                        type={type}
                        min={min}
                        style={{ width: width, marginLeft: 0 }}
                        value={val}
                        step={step}
                        onChange={onChange}
                        placeholder={placeHolder}
                    />
                </td>
            </tr>
        );
    };

    const textAreaValue = (label, name, title, link = null) => {
        const val = pending.active[name] !== undefined ? pending.active[name] : pending[name];
        const rows = Math.max(3, Math.min(12, val.split("\n").length + 1));
        const style = smallWindow ? { width: "20ch" } : { minWidth: "40ch", width: "60ch" };

        return (
            <tr title={title}>
                <td>
                    {label}
                    {link}
                </td>
                <td>
                    <textarea name={name} value={val} rows={rows} style={style} onChange={onChangeText}></textarea>
                </td>
            </tr>
        );
    };

    const textLine = (label, text, style = {}) => {
        return (
            <tr>
                <td>{label}</td>
                <td style={style}>{text}</td>
            </tr>
        );
    };

    const options = (label, name, vals, onChange, title, useIndexes = false) => {
        const val = pending.active[name] !== undefined ? pending.active[name] : pending[name];

        return (
            <tr title={title}>
                <td>{label}</td>
                <td>
                    <select className="combo combo-settings" name={name} value={val} onChange={onChange}>
                        {vals.map((v, i) => (
                            <option value={useIndexes ? i : v} key={i}>
                                {v}
                            </option>
                        ))}
                    </select>
                </td>
            </tr>
        );
    };

    const yesNo = (label, name, title = "") => {
        return (
            <tr title={title}>
                <td>{label}</td>
                <td>
                    <select
                        className="combo combo-settings"
                        name={name}
                        value={pending.active[name]}
                        onChange={onChangeBool}
                    >
                        <option value={false}>NO</option>
                        <option value={true}>YES</option>
                    </select>
                </td>
            </tr>
        );
    };

    function trButton(label, buttonText, fn) {
        return (
            <tr>
                <td>{label}</td>
                <td>{button(buttonText, fn, "", "btn-smaller")} </td>
            </tr>
        );
    }

    function button(label, onClick, title = "", classes = "btn-modal btn-modal-bottom") {
        return (
            <button className={classes} onClick={onClick} title={title}>
                {label}
            </button>
        );
    }

    function octoTest() {
        const opts = { position: "top-center" };

        if (octoTesting) return cogoWarn("A test is already in progress", opts);

        const cfg = octo.settingsToConfig(pending);
        if (cfg.error) return cogoError(cfg.error, opts);

        setOctoTesting(true);

        octo.verify(cfg, error => {
            if (error) {
                cogoError(error, opts);
                gaEvt("otest_err", { error });
            } else {
                cogoSuccess("OctoPrint communication was successful", opts);
                gaEvt("otest_ok");
            }

            setOctoTesting(false);
        });
    }

    const octoSettings = () => {
        if (!pending.active.octoEnabled) return yesNo("OctoPrint Enabled", "octoEnabled");

        return (
            <React.Fragment>
                {yesNo("OctoPrint Enabled", "octoEnabled")}
                {simpleText("OctoPrint Host", "octoHost", OCTO_HOST_TIP, "octopi.local")}
                {simpleNum("OctoPrint Port", "octoPort", 0, 99999)}
                {simpleText("OctoPrint API Key", "octoKey", OCTO_KEY_TIP, "Get from the OctoPrint API settings")}
                {options("OctoPrint Root", "octoRoot", ["/api/files/local/", "/api/files/sdcard/"], onChangeText)}
                {simpleText("OctoPrint Subfolder", "octoFolder", OCTO_FOLDER_TIP, "optional")}
                {yesNo("OctoPrint Start After Send", "octoSendAndPrint")}
                {trButton("OctoPrint Test", octoTesting ? "Testing" : "Test", octoTest)}
            </React.Fragment>
        );
    };

    const retractionSettings = () => {
        if (!pending.active.retractionEnabled)
            return yesNo("Retraction Enabled", "retractionEnabled", RETRACTION_ENABLED_TIP);

        return (
            <React.Fragment>
                {yesNo("Retraction Enabled", "retractionEnabled", RETRACTION_ENABLED_TIP)}
                {simpleNum("Retraction Distance (mm)", "retractionDistance", 0, 1000)}
                {simpleNum("Retraction Speed (mm/s)", "retractionSpeed", 0, 1000, RETRACTION_SPEED_TIP)}
                {simpleNum("Prime Distance (mm)", "primeDistance", 0, 1000, PRIME_DISTANCE_TIP)}
            </React.Fragment>
        );
    };

    const modalStyle = { minWidth: 200, padding: 20, overflowY: "auto", maxHeight: "90%" };
    const titleStyle = { marginTop: -5, marginBottom: 15, fontSize: "x-large" };
    const profNames = Object.values(pending.profiles).map(p => p.name);
    const red = { color: "red" };
    const smaller = { fontSize: "smaller" };

    return (
        <div id="settings" className="modal-background hcenter">
            <div id="settings-modal" className="panel modal" style={modalStyle}>
                <div className="panel-title" style={titleStyle}>
                    Settings
                </div>
                <table id="settings-table">
                    <tbody>
                        {options("Printer Profile", "activeProfile", profNames, onChangeNum, "", true)}
                        {simpleText("Profile Name", "name", "")}
                        {simpleNum("Extruders", "toolCnt", 1, 10)}
                        {pending.isSingleExtruder ? textLine("Single Extruder Requirement", SINGLE_EXT_REQ, red) : null}
                        {pending.isSingleExtruder ? textLine("Single Extruder Note", SINGLE_EXT_NOTE) : null}
                        {textLine("Filament Change Syntax", FILAMENT_CHG_LINK, smaller)}
                        {textAreaValue("Filament Change G-Code", "filamentChangeText", FILAMENT_CHG_CMDS_TIP)}
                        {options("Height Precision", "minStep", [0.1, 1], onChangeNum, PRECISION_TIP)}
                        {simpleText("Output Filename Format", "outFileFormat", OUT_FORMAT_TIP)}
                        {retractionSettings()}
                        {octoSettings()}
                    </tbody>
                </table>
                <div className="panel-contents btn-row">
                    {button(
                        reloadNeeded() ? "Save and Reload" : "Save",
                        onSave,
                        "Save all profile settings and reload if needed"
                    )}
                    {button("Reset", onReset, "Reset the active profile's settings to the default settings")}
                    {button("Add", onAddProfile, "Add a new profile")}
                    {button("Copy", onCopyProfile, "Copy the current profile to a new one")}
                    {button("Delete", onDeleteProfile, "Delete the current profile")}
                    {button("Cancel", onClose)}
                </div>
            </div>
        </div>
    );
}
