import React, { Component } from "react";
import { gaEvt } from "../utils/ga";
import { EMAIL } from "../utils/globals";

const button = (filename, alt) => (
  <img
    src={require("../resources/" + filename)}
    alt={alt}
    width="32px"
    height="32px"
    style={{ verticalAlign: "bottom" }}
  />
);

const OPEN_BUTTON = button("btn-open.svg", "Open File");
const SAVE_BUTTON = button("btn-save.svg", "Save File");

const FACEBOOK = anchor(
  <img src={require("../resources/thefacebook.svg")} alt="->" width="16px" height="16px" title="Facebook" />,
  "https://www.facebook.com/G-Code-Mix-Master-108852760750391/"
);

const theProcess = [
  `Generate a G-code file using one filament with your favorite slicer.`,
  <span>
    Open the file by dragging into onto this webpage or using the{OPEN_BUTTON}button at the bottom of the page.
  </span>,
  `Configure and add modifiers to get the desired pattern.`,
  <span>Save the new G-code file with the{SAVE_BUTTON}button at the bottom of the page.</span>,
  `Transfer the new G-code file to your printer and start printing.`,
];

const generalInfo = [
  'This app is intended for use with a 3D printer that has multiple extruders and a "mixing" hotend or a single extruder printer using manual filament changes to create stripes.',
  "Your files are processed locally in the browser and never uploaded to the cloud.",
  "No purging is necessary with these color changes.",
  "If you are using a modifier from height zero, it is recommended to have a large enough skirt, raft, or brim to get the mix right before the first layer starts.",
  "For cleaner stripes, set your slicer to print infill before walls.",
  `If you clear your browsing data in the browser, the settings for this website may need to be re-entered.`,
  "If using OctoPrint, you must update your OctoPrint printer profile to have 16 more extruders than your printer has in order to support the maximum number of virtual extruders. You may need to type the number in instead of using the arrows in the input box.",
  "The G-Code Mix Master configuration for an output file is written to the beginning of the file for future reference.",
  `All inserted or removed lines in the resulting G-code file should have a comment with "GCMM" including the modifier number and height when available.`,
  "The current color preview implementation uses RYB averaging.  This will result in some incorrect color mixes (red + yellow = brown).  This will not affect the final print.",
  "This app will remove any mixing commands that were in the original file (M163-M166).",
  "Custom G-code in the original file may cause issues if it interferes with the ability to uniquely map line numbers to heights and vice versa.",
  "There is currently no support for the M166 gradient mix command as it is redundant and requires Marlin 2.x.",
  "Use this app at your own risk.",
  <div>
    Send comments or questions to {email(EMAIL)} and for more examples and info check out the page on {FACEBOOK}
  </div>,
];

const references = [
  link(
    "Fidget Spinner",
    "https://www.thingiverse.com/thing:53451",
    "A STRIPES pattern in the middle while the original top and bottom layers with purging were left intact"
  ),
  link("Iris Box", "https://www.thingiverse.com/thing:1817180", "A STRIPES pattern starting after the base"),
  link(
    "Benchy",
    "https://www.thingiverse.com/thing:763622",
    "STRIPES patterns with the leftmost Benchy sliced on an angle to create an angled stripes effect"
  ),
  link("Spiral Vase", "https://www.thingiverse.com/thing:550604", "A RANDOM pattern"),
  link("Black and Red Teardrop Vase", "https://www.thingiverse.com/thing:1359035", "An uneven STRIPES pattern"),
  link(
    "Colorful Teardrop Vase",
    "https://www.thingiverse.com/thing:1359035",
    "A STRIPES pattern with filament changes"
  ),
  link("Moai Head", "https://www.thingiverse.com/thing:1999162", "A STRIPES pattern and a filament change"),
  link("Cylinder", "", "A STRIPES pattern on top and bottom with a 7 level boomerang GRADIENT pattern in the middle"),
  link("Rose", "https://www.thingiverse.com/thing:283738", "A STRIPES pattern with 3 colors"),
  link(
    "Spiral Box",
    "https://www.thingiverse.com/thing:2106386",
    "A STRIPES pattern with an overlapping GRADIENT BOOMERANG pattern"
  ),
];

const faqs = [
  {
    q: "What is a VIRTUAL MIX?",
    a: [
      `A virtual tool is a preset mix of filaments that can be used as an "extra color" in patterns. ` +
        `Maybe you have a blue filament in T0 and yellow in T1.  You can assign T8 to be a virtual ` +
        `tool that is 50% T0 and 50% T1 which will be green. You can then use T8 as a tool in stripes ` +
        `or random patterns and it'll show up as green.`,
    ],
  },
  {
    q: "How do I change the filament colors that are used in the preview?",
    a: [
      `Just click on the color for the tool in the "Physical Colors" section.  Note that "Virtual Colors" ` +
        `cannot be changed manually as they are derived from the configured virtual mix.`,
    ],
  },
  {
    q: `What does it mean to enable OVERLAP for a modifier?`,
    a: [
      `This feature will allow you to apply a GRADIENT or RANDOM MIX pattern to a physical tool while that ` +
        `tool is used in another pattern.  For example, you could have a stripes pattern that alternates ` +
        `between T0 and T1.  And then you could apply an overlapping gradient to T1 while T0 stays the same ` +
        `color as in the spiral box example.`,
      `Another use for OVERLAP is to apply it to a file that already uses multiple colors.  You can apply a ` +
        `gradient to one of the physical tools used in the file so that maybe Benchy's trim transitions from ` +
        `blue to red from bottom to top.`,
    ],
  },
  {
    q: `What does this setting do?`,
    a: [
      <div>
        If you are unsure about a setting, move your mouse over the setting and it may show more details about its
        functionality. If it's still not clear, try changing the option to learn how it affects the model in the
        preview. If that still doesn't do the trick, you can send an email to {email(EMAIL)} for clarification.
      </div>,
    ],
  },
  {
    q: `How does FILAMENT CHANGE work?`,
    a: [
      `See the examples above to see what can be accomplished with this feature.  The M600 G-code command in the ` +
        `"Filament Change G-Code" will instruct the printer to unload the filament and prompt the user to load new ` +
        `filament. Once loaded, the tip of the nozzle will contain 100% of the new filament.  If the current pattern ` +
        `does not call for 100% of that filament, then a warning will appear as it will cause an unwanted stripe in ` +
        `the print.  You can modify the FILAMENT CHANGE HEIGHT to get to a level where this won't be an issue ` +
        `and the warning goes away.`,
    ],
  },
  {
    q: `What needs to be done for OctoPrint to work?`,
    a: [
      `In the OctoPrint API settings, CORS must be enabled and will require a restart of OctoPrint when changed.`,
      `In the OctoPrint printer profile, the number of extruders should be set to 20 by typing in the number.`,
      `Due to security concerns with accessing the OctoPrint server using HTTP, browsers will block access to it by default.`,
      link(
        `Follow these instructions to enable "Mixed Mode" in the browser in order to communicate with OctoPrint over HTTP`,
        `https://docs.adobe.com/content/help/en/target/using/experiences/vec/troubleshoot-composer/mixed-content.html`
      ),
    ],
  },
  {
    q: `Why didn't anything happen when I saved the file?`,
    a: [
      <div>
        Some browser settings or extensions may prevent a website from saving a file locally. To resolve this, you may
        try enabling third party cookies or try another browser. For support with the issue, send an email to{" "}
        {email(EMAIL)}
      </div>,
    ],
  },
];

export default class InfoModal extends Component {
  onEscape = e => {
    if (e.keyCode === 27) this.onClose();
  };

  componentDidMount() {
    document.addEventListener("keyup", this.onEscape);
    gaEvt("info_modal");
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEscape);
  }

  onClose = () => {
    this.props.onChangeAppState({ infoModal: false });
  };

  render() {
    const modalStyle = { padding: "5px", fontSize: "large" };
    const itemStyle = { overflowY: "auto", width: "100%", boxSizing: "border-box", animation: "none" };

    return (
      <div id="info" className="modal-background">
        <div id="info-modal" className="panel modal-full flex-column" style={modalStyle}>
          <img className="btn btn-small btn-x" src={require("../resources/btn-x.svg")} onClick={this.onClose} alt="X" />
          <div className="panel-item" style={itemStyle}>
            {processBlock()}
            {infoBlock("General Info", generalInfo)}
            {exampleBlock()}
            {infoBlock("", references)}
            {faqBlock()}
            {closeBlock(this.onClose)}
          </div>
        </div>
      </div>
    );
  }
}

function link(name, url, comments) {
  return (
    <div>
      {url ? anchor(name, url) : name}
      {comments ? " - " + comments : null}
    </div>
  );
}

function email(addr) {
  return anchor(addr, "mailto:" + addr);
}

function anchor(content, url) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  );
}

function processBlock() {
  return (
    <React.Fragment>
      <div className="panel-title">The Process</div>
      <ol style={{ marginTop: "8px" }}>
        {theProcess.map((txt, i) => (
          <li key={i}>{txt}</li>
        ))}
      </ol>
    </React.Fragment>
  );
}

function infoBlock(header, items) {
  return (
    <React.Fragment>
      <div className="panel-title">{header}</div>
      <ul style={{ marginTop: "8px" }}>
        {items.map((txt, i) => (
          <li key={i}>{txt}</li>
        ))}
      </ul>
    </React.Fragment>
  );
}

function exampleBlock() {
  return (
    <React.Fragment>
      <div className="panel-title">Examples</div>
      <div className="panel-contents">{references.map((v, i) => sample(i + 1))}</div>
      <br />
    </React.Fragment>
  );
}

function sample(idx) {
  const style = { display: "block", margin: "auto", padding: "4px" };

  return <img style={style} src={require(`../resources/sample${idx}.png`)} alt="No sample" key={idx} />;
}

function faqBlock() {
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div className="panel-title">FAQ</div>
      {faqs.map((f, i) => faq(f, i))}
    </div>
  );
}

function faq(f, i) {
  const qStyle = { fontWeight: "bold" };
  const aStyle = { marginLeft: "2ch", marginTop: "5px", marginBottom: "10px" };

  return (
    <div key={i}>
      <div style={qStyle}>{f.q}</div>
      {f.a.map((a, i) => (
        <div key={i} style={aStyle}>
          {a}
        </div>
      ))}
    </div>
  );
}

function closeBlock(onClose) {
  return (
    <div className="hcenter">
      <button id="btn-close-info btn-modal-bottom" onClick={onClose}>
        Close
      </button>
    </div>
  );
}
