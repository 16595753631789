import React from "react";
import { stepRound, numToWidth } from "../utils/utils";
import YesNoItem from "./YesNoItem";

const MOD_LOCK_TIP =
    "This will lock the end height of a modifier to be the same as the start height of the next modifier.";

export default function FileDetailsPanel({ settings, config, fileDetails, onChangeAppState, onChangeConfigValue }) {
    if (!fileDetails.fileName) return null;

    const onChangeProfile = ev => {
        onChangeAppState({}, settings.copy({ activeProfile: ev.target.value, save: true }));
    };

    const options = (label, name, vals, onChange, title, useIndexes = false) => {
        return (
            <div className="panel-item" title={title}>
                {label}
                <select name={name} className="combo" value={settings[name]} onChange={onChange}>
                    {vals.map((v, i) => (
                        <option value={useIndexes ? i : v} key={i}>
                            {v}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    const profNames = Object.values(settings.profiles).map(p => p.name);

    return (
        <div id="file-details" className="panel">
            <div className="panel-title">{fileDetails.fileName}</div>
            <div className="panel-contents" style={{ marginBottom: "10px" }}>
                {numberReadOnly("Lines", 1, fileDetails.lines.length)}
                {numberReadOnly("First Layer Height", 0.01, fileDetails.obj.firstLayerHeight)}
                {numberReadOnly("Top Layer Height", 0.01, fileDetails.obj.maxHeight)}
            </div>
            <div className="panel-contents">
                {options("Printer Profile", "activeProfile", profNames, onChangeProfile, "", true)}
                {settings.advanced ? (
                    <YesNoItem
                        label="Mod Lock"
                        name="modLock"
                        value={config.modLock}
                        onChange={onChangeConfigValue}
                        title={MOD_LOCK_TIP}
                    />
                ) : null}
            </div>
        </div>
    );
}

function numberReadOnly(label, step, value) {
    if (value == null) return null;

    value = stepRound(value, step);

    const width = numToWidth(value, 0, 0);

    return (
        <div className={"panel-item"}>
            {label}
            <input type="text" className="readonly" value={value} style={{ minWidth: width, width: width }} readOnly />
        </div>
    );
}

// function textReadOnly(txt) { return <div className="text-center panel-item vcenter">{txt}</div>; }
