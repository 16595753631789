import Change from "../classes/Change";

const OP_ADD = "ADD";
const OP_REM = "REM";

let lastArgs = [];
let lastResult = null;

function sameArgs(a) {
    return a.length === lastArgs.length && a.every((value, index) => value === lastArgs[index]);
}

function isProcessed(settings, cfg, fileDetails, meta) {
    return sameArgs([...arguments]);
}

function process(settings, cfg, fileDetails, meta) {
    // settings is used in memoization check
    const args = [...arguments];
    if (sameArgs(args)) return lastResult;

    const changeLines = [];
    const obj = fileDetails.obj;
    const mods = cfg.blockMods || [];
    let lineNumber = 0;
    let height = 0;
    let startMs = new Date();

    const outLines = stringifyConfig();

    for (let line of fileDetails.lines) {
        lineNumber++;
        height = obj.lineToHeightTree.findFloor(lineNumber);

        insertLines();

        if (!removeLine(line)) outputLine(line); // Lines that aren't consumed just get added as is
    }

    // Do commands for the end of the file
    lineNumber++;
    insertLines();

    lastArgs = args;
    lastResult = [outLines, changeLines];

    console.log(`Processing took ${new Date() - startMs}ms`);

    return [outLines, changeLines];

    function stringifyConfig() {
        const str = JSON.stringify(cfg, null, 2).replace(/\n/g, "\n; ");
        return `; ${new Date().toLocaleString()}\n; G-Code Mix Master Config:\n; ${str}\n\n`
            .split("\n")
            .map(l => l + "\n");
    }

    function insertLines() {
        const cmds = meta.newCommands[lineNumber];
        if (!cmds) return;

        for (let outer of cmds) {
            for (let c of outer.cmds) {
                c = c.trim();
                const outLine = c === "" ? "" : `${c} ; ${comment("ADDED LINE", outer.m.id)}`;
                outputLine(outLine);
                addChangeLine(c, OP_ADD, outer.m.id);
            }
        }
    }

    function removeLine(line) {
        const isM16x = line.match(/^M16[3-6]+/) !== null;
        const isTx = line.match(/^T\d+/) !== null;

        if (!isM16x && !isTx) return false;

        // If the line is in the range of a mod that doesn't overlap, then remove it
        const modsInRange = mods.filter(m => !m.overlaps() && m.inRange(height));
        if (isTx && modsInRange.length === 0) return false;

        outputLine(`; ${comment("REMOVED LINE")} Line="${line}"`);
        addChangeLine(line, OP_REM, modsInRange.length > 0 ? modsInRange[0].id : null);

        return true;
    }

    function outputLine(str) {
        outLines.push(str + "\n");
    }

    function addChangeLine(text, op, modId) {
        changeLines.push(new Change(op, lineNumber, outLines.length, modId, height, text));
    }

    function comment(op, modId = null) {
        const secInfo = modId ? ` Mod=${modId}` : ``;
        return `GCMM ${op} -${secInfo} Height=${height.toFixed(2)}`;
    }
}
export { process, isProcessed };
