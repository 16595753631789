import { Pattern } from "../Pattern";
import { getFilamentChangeCmds, isNumber } from "../../utils/utils";
import { MAX_LEVELS } from "../../utils/globals";

const ModType = Object.freeze({
    STRIPES: "STRIPES",
    STRIPES_ADVANCED: "STRIPES ADVANCED",
    STRIPED_GRADIENT: "STRIPED GRADIENT",
    GRADIENT: "GRADIENT",
    RANDO_MIX: "RANDOM MIX",
    RANDO_TOOL: "RANDOM TOOL",
    SOLID: "SOLID",
    FILAMENT_CHANGE: "FILAMENT CHANGE",
    VIRTUAL_MIX: "VIRTUAL MIX",
    CUSTOM_GCODE: "CUSTOM GCODE",
    HEIGHT_PATTERNS: "HEIGHT_PATTERNS"
});

const modTypes = settings => Object.values(ModType);
const modTypesSingleExtruder = [
    ModType.STRIPES,
    ModType.STRIPES_ADVANCED,
    ModType.RANDO_TOOL,
    ModType.SOLID,
    ModType.FILAMENT_CHANGE,
    ModType.CUSTOM_GCODE
];

function getModTypes(settings) {
    if (settings.isSingleExtruder) return modTypesSingleExtruder;

    return modTypes(settings).filter(t => settings.experimental || t !== ModType.HEIGHT_PATTERNS);
}

class Mod {
    constructor({
        type,
        id,
        startHeight,
        endHeight,
        endTool = "",
        mixTool = "T0",
        overlap = false, // Only used for mixing mods
        boomerang = false,
        gradientRepeatCount = 1,
        gradientWeights = [
            { T0: 100, T1: 0, T2: 0 },
            { T0: 0, T1: 100, T2: 0 }
        ],
        gradientLevels = MAX_LEVELS,
        patterns = [new Pattern("T0"), new Pattern("T1")],
        filamentChangeHeight = 0,
        filamentChangeTool = "T0",
        filamentChangeColor = "#FF8000",
        randoTools = {},
        randoLevels = 20,
        randoSeed = 0,
        randoWeightRanges = {},
        virtualMixTool = "",
        virtualMixWeights = { T0: 100, T1: 0, T2: 0 },
        customGcodeHeightStep = 10,
        customGcodeVarStart = 0,
        customGcodeVarStep = 1,
        customGcodeText = ""
    }) {
        this.type = type;
        this.id = id;
        this.startHeight = startHeight;
        this.endHeight = endHeight;
        this.endTool = endTool;
        this.mixTool = mixTool;
        this.overlap = overlap;
        this.boomerang = boomerang;
        this.gradientRepeatCount = gradientRepeatCount;
        this.gradientWeights = gradientWeights;
        this.gradientLevels = gradientLevels;
        this.patterns = patterns;
        this.filamentChangeHeight = filamentChangeHeight;
        this.filamentChangeTool = filamentChangeTool;
        this.filamentChangeColor = filamentChangeColor;
        this.randoTools = randoTools;
        this.randoLevels = randoLevels;
        this.randoSeed = randoSeed;
        this.randoWeightRanges = randoWeightRanges;
        this.virtualMixTool = virtualMixTool;
        this.virtualMixWeights = virtualMixWeights;
        this.customGcodeHeightStep = customGcodeHeightStep;
        this.customGcodeVarStart = customGcodeVarStart;
        this.customGcodeVarStep = customGcodeVarStep;
        this.customGcodeText = customGcodeText;

        this.totalHeight = 0;
    }

    inRange(height) {
        return height >= this.startHeight && height < this.endHeight;
    }

    hasZeroHeight() {
        return !isNumber(this.startHeight) || !isNumber(this.endHeight) || this.startHeight >= this.endHeight;
    }

    conflicts(o) {
        if (this.overlaps() || o.overlaps()) return false;

        if (this.hasZeroHeight() || o.hasZeroHeight()) return false;

        return this.inRange(o.startHeight) || (o.endHeight !== this.startHeight && this.inRange(o.endHeight));
    }

    overlaps() {
        return (
            ([ModType.GRADIENT, ModType.RANDO_MIX].includes(this.type) && this.overlap) ||
            this.type === ModType.CUSTOM_GCODE
        );
    }

    addPattern(p) {
        this.patterns.push(p);
    }

    changePattern(i, p) {
        this.patterns[i] = p;
    }

    remPattern(i) {
        this.patterns.splice(i, 1);
    }

    isBlockMod() {
        return this.type !== ModType.FILAMENT_CHANGE && this.type !== ModType.VIRTUAL_MIX;
    }

    isMixing() {
        return this.type === ModType.GRADIENT || this.type === ModType.RANDO_MIX;
    }

    randoWeightRange(tool) {
        return this.randoWeightRanges[tool] || [0, 100];
    }

    // Child methods
    getError(settings) {
        return "";
    }

    fillMeta(settings, fileDetails, meta) {}

    getStartTool(meta) {
        return "T0";
    }

    getToolChangeCmds(settings, tool, prevTool, height, comment = "") {
        if (settings.isSingleExtruder) return height > 0 ? getFilamentChangeCmds(settings, "T0") : [];

        if (!settings.retractionEnabled || height === 0 || prevTool === tool) return [tool + comment];

        const primeCmds =
            settings.primeDistance !== 0
                ? [`G1 E${settings.primeDistance} F${settings.retractionSpeed * 60}`, `G92 E0`]
                : [`G92 E0`];

        return [
            `G92 E0`,
            `G1 E-${settings.retractionDistance} F${settings.retractionSpeed * 60}`,
            tool + comment
        ].concat(primeCmds);
    }
}

export { ModType, getModTypes, Mod, Pattern };
