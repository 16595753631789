import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { isNumber } from "../../utils/utils";

export default class ModCustomGcode extends Mod {
    fillMeta(settings, fileDetails, meta) {
        if (
            !isNumber(this.customGcodeVarStart) ||
            !isNumber(this.customGcodeVarStep) ||
            !isNumber(this.customGcodeHeightStep) ||
            this.customGcodeHeightStep <= 0
        )
            return;

        const varStart = Number(this.customGcodeVarStart);
        const varStep = Number(this.customGcodeVarStep);
        const heightStep = Number(this.customGcodeHeightStep);

        let idx = 0;
        const endHeight = Math.min(this.endHeight, fileDetails.obj.maxHeight + 0.00001);

        for (let height = this.startHeight; height < endHeight; height += heightStep) {
            const node = fileDetails.obj.heightToLineTree.findCeilingNode(height);
            if (node === null) break;

            const v = varStart + idx * varStep;
            const t = meta.lineToToolTree.findFloor(node.data);

            const text = this.customGcodeText
                .trim()
                .replace(/%v/g, Math.round(v * 1000) / 1000)
                .replace(/%V/g, Math.round(v))
                .replace(/%t/g, t.slice(1))
                .replace(/%T/g, t)
                .replace(/%h/g, Math.round(height * 1000) / 1000)
                .replace(/%H/g, Math.round(height))
                .split("\n");

            meta.newCommands.add(node.data, new Commands(this, height, text));
            idx++;
        }
    }
}
