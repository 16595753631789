import React from "react";
import { toolOptions } from "./ComponentCommon";
import {} from "../utils/utils";
import { ModType } from "../classes/mods/Mod";
import { Slider } from "./Slider";
import YesNoItem from "./YesNoItem";
import { ButtonAdd } from "./ButtonAdd";

const BOOM_TIP = "If enabled, the stripes will transition from start height to end height and back to start height";

export default function ModPanelStripes({
  settings,
  fileDetails,
  meta,
  m,
  onDeletePattern,
  onAddPattern,
  onChangeValue
}) {
  function pattern(p, idx) {
    const max = Math.min(Math.round(fileDetails.obj.maxHeight), 200) / 2;
    const step = settings.minStep;
    const isBasic = m.type === ModType.STRIPES;
    let classes = "config-pattern panel-item " + (isBasic ? "flex-row" : "advanced-stripes-pattern");

    return (
      <div className={classes} key={idx}>
        {toolOptions(settings, meta.tools, p.tool, "tool", idx, onChangeValue)}
        {heightInput(isBasic ? "Height" : "Start Height", "height")}
        {isBasic ? null : heightInput("End Height", "endHeight")}
        {isBasic ? null : (
          <YesNoItem
            label="Boomerang"
            name="boomerang"
            idx={idx}
            value={p.boomerang}
            onChange={onChangeValue}
            title={BOOM_TIP}
            classes={"panel-data"}
          />
        )}
        {idx >= 2 ? (
          <img
            className="btn btn-small btn-pattern-delete btn-delete vcenter"
            src={require("../resources/btn-x.svg")}
            onClick={() => onDeletePattern(idx)}
            alt="x"
            title="Delete stripe"
          />
        ) : null}
      </div>
    );

    function heightInput(label, name) {
      return (
        <div className="panel-data flex-row" style={{}}>
          <div className="label">{label}</div>
          <Slider name={name} val={p[name]} min={step} max={max} step={step} idx={idx} onChange={onChangeValue} />
        </div>
      );
    }
  }

  const classes = "panel-contents" + (m.patterns.length > 2 ? " flex-column" : "");

  return (
    <React.Fragment>
      <div className={classes} style={{ alignItems: "normal" }}>
        {m.patterns.map((p, i) => pattern(p, i))}{" "}
      </div>
      <ButtonAdd title="Add a stripe" onClick={onAddPattern} />
    </React.Fragment>
  );
}
