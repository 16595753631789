import { Mod } from "./Mod";
import { Commands } from "../Commands";

export default class ModStripesAdvanced extends Mod {
    fillMeta(settings, fileDetails, meta) {
        let index = 0;
        const endHeight = Math.min(this.endHeight, fileDetails.obj.maxHeight + 0.00001);
        const totalHeight = endHeight - this.startHeight;

        for (let height = this.startHeight; height < endHeight; ) {
            const p = this.patterns[index++ % this.patterns.length];
            const node = fileDetails.obj.heightToLineTree.findCeilingNode(height);
            if (node === null) break;

            const prevTool = meta.lineToToolTree.findFloor(node.data);

            meta.lineToToolTree.replaceIfChanged(node.data, p.tool);

            meta.newCommands.add(
                node.data,
                new Commands(this, height, this.getToolChangeCmds(settings, p.tool, prevTool, height))
            );

            height += this.progressToHeight(p, height, totalHeight);
        }
    }

    getStartTool(meta) {
        return this.patterns[0].tool;
    }

    // Ideally this will know exactly how many stripes there will be and
    // then figure it out based on that instead of a % which won't be perfect
    progressToHeight(p, height, totalHeight) {
        let pct = (height - this.startHeight) / totalHeight;
        if (p.boomerang) pct = pct <= 0.5 ? pct * 2 : 2 - pct * 2;
        return Math.max(0, p.height + (p.endHeight - p.height) * pct);
    }
}
