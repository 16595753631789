import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { sumValues, seededPRNG, getMixResetCmds } from "../../utils/utils";
import { mixColorsByWeight, rgbToHexString, endColorMap, colorMapToRgbMap } from "../../utils/colors";

export default class ModRandoMix extends Mod {
  getError(settings) {
    if (!this.validWeights(settings.physicalTools)) return "TWO TOOLS MUST BE ENABLED IN THE RANDOM MIX MODIFIER";

    return "";
  }

  validWeights(tools) {
    return tools.reduce((sum, t) => sum + (this.randoWeightRange(t)[1] > 0 ? 1 : 0), 0) > 1;
  }

  getStartTool(meta) {
    return this.mixTool;
  }

  fillMeta(settings, fileDetails, meta) {
    if (!this.validWeights(settings.physicalTools)) return;

    this.startCommands(fileDetails, meta); // Set the current tool to the rando tool if not leaving tool changes

    let prevLine = null;
    const obj = fileDetails.obj;
    const endHeight = Math.min(this.endHeight, obj.maxHeight + 0.00001);
    const origColorTree = meta.heightToColorMapTree.copyFloor(this.startHeight, endHeight);
    const totalHeight = endHeight - this.startHeight;
    if (totalHeight <= 0) return;

    const mmPerStep = totalHeight / this.randoLevels;
    const tools = settings.physicalTools;
    const weightsArray = this.getWeightsArray(tools);

    // Remove any nodes that conflict with this modifier
    const startNode = origColorTree.findCeilingNode(this.startHeight);
    for (let node = startNode; node && node.key < this.endHeight; node = origColorTree.nextNode(node)) {
      meta.heightToColorMapTree.remove(node.key);
    }

    for (let height = this.startHeight; height < endHeight; height += mmPerStep) {
      const colorMapNode = origColorTree.findFloorNode(height); // The original color map at this height
      const colorMap = colorMapNode.data;
      const rgbMap = colorMapToRgbMap(colorMap, tools);
      const weights = weightsArray.shift();
      const rgb = mixColorsByWeight(tools, rgbMap, weights);

      // Check if there is a filament change between this height and the next height and apply the mix to it
      this.processMidLevelColorChange(
        settings,
        obj,
        origColorTree,
        colorMapNode,
        height + mmPerStep,
        tools,
        weights,
        meta
      );

      const curLine = obj.heightToLineTree.findCeiling(height);
      if (curLine === prevLine) continue; // Avoid doing multiple changes on the same line

      prevLine = curLine;

      meta.heightToColorMapTree.replace(height, { ...colorMap, [this.mixTool]: rgbToHexString(rgb) });

      meta.newCommands.add(curLine, new Commands(this, height, this.getMixCmds(settings, weights, meta, curLine)));
    }

    this.endCommands(fileDetails, settings, meta); // End commands to reset the mix

    endColorMap(origColorTree, fileDetails, meta, endHeight); // Put the color map back afterwards
  }

  startCommands(fileDetails, meta) {
    if (this.overlap) return;

    const startLine = fileDetails.obj.heightToLineTree.findCeiling(this.startHeight);
    meta.lineToToolTree.replaceIfChanged(startLine, this.mixTool);
    meta.newCommands.add(startLine, new Commands(this, this.startHeight, [this.mixTool.toString()]));
  }

  endCommands(fileDetails, settings, meta) {
    const endLine = fileDetails.obj.heightToLineTree.findCeiling(this.endHeight) || fileDetails.lines.length;
    let endCmds = getMixResetCmds(settings, this.mixTool);
    endCmds = this.appendToolIfOverlapping(meta, endLine, endCmds);

    meta.newCommands.add(endLine, new Commands(this, this.endHeight, endCmds));
  }

  /**
   * Get an array that has a weight mix for each height level and boomerangs if enabled
   **/
  getWeightsArray(tools) {
    const rng = seededPRNG(this.randoSeed);
    const indexToWeights = [];
    const subVal = this.randoLevels - 1;

    for (let i = 0; i < this.randoLevels; i++) {
      const t = !this.boomerang || i < this.randoLevels / 2 ? this.getWeights(tools, rng) : indexToWeights[subVal - i];
      indexToWeights.push(t);
    }

    // Sometimes there is an extra little sliver of height at the end due to float math
    // Repeat the last tool once just in case
    indexToWeights.push(indexToWeights[indexToWeights.length - 1]);

    return indexToWeights;
  }

  getWeights(tools, rng) {
    const weights = {};

    do {
      for (let t of tools) {
        const [minWeight, maxWeight] = this.randoWeightRange(t);
        weights[t] = Math.round(minWeight + (maxWeight - minWeight) * rng());
      }
    } while (sumValues(weights) === 0);

    return weights;
  }

  getMixCmds(settings, weights, meta, curLine) {
    let cmds = settings.physicalTools
      .map((t, i) => `M163 S${i} P${weights[t] || 0}`)
      .concat(`M164 S${this.mixTool.slice(1)}`);

    return this.appendToolIfOverlapping(meta, curLine, cmds);
  }

  // If leaving changes, we need to put the tool back after M164 changes it
  appendToolIfOverlapping(meta, lineNum, cmds) {
    if (this.overlap) {
      const curTool = meta.lineToToolTree.findFloor(lineNum);
      if (curTool !== this.mixTool) cmds = cmds.concat(curTool);
    }

    return cmds;
  }

  processMidLevelColorChange(settings, obj, origColorMapTree, colorMapNode, nextHeight, tools, weights, meta) {
    const nextNode = origColorMapTree.nextNode(colorMapNode);
    if (nextNode && nextNode.key < nextHeight) {
      const rgbMap = colorMapToRgbMap(nextNode.data, tools);
      const rgb = mixColorsByWeight(tools, rgbMap, weights);
      meta.heightToColorMapTree.replace(nextNode.key, { ...nextNode.data, [this.mixTool]: rgbToHexString(rgb) });

      const lineNum = obj.heightToLineTree.findCeiling(nextNode.key);
      meta.newCommands.add(
        lineNum,
        new Commands(this, nextNode.key, this.getMixCmds(settings, weights, meta, lineNum))
      );
    }
  }
}
