import { numBoolVal } from "../utils/utils";

const ProfileDefaults = Object.freeze({
  name: "Default",
  toolCnt: 2,
  virtualToolCnt: 16, // MIXING_VIRTUAL_TOOLS in Marlin 1.x and 2.x
  outFileFormat: "%f - gcmm.%e",
  filamentChangeText: "M600 %T",
  minStep: 0.1,
  retractionEnabled: false,
  retractionDistance: 90,
  retractionSpeed: 160,
  primeDistance: 0,
  octoEnabled: false,
  octoHost: "",
  octoPort: 80,
  octoRoot: "/api/files/local",
  octoFolder: "",
  octoKey: "",
  octoSendAndPrint: false,
});

const GeeetechMDefaults = { ...ProfileDefaults, name: "Geeetech A10M A20M A30M" };
const GeeetechTDefaults = { ...ProfileDefaults, name: "Geeetech A10T A20T A30T", toolCnt: 3 };
const SingleExtruderDefaults = { ...ProfileDefaults, name: "Single Extruder", toolCnt: 1 };

const SettingDefaults = Object.freeze({
  colorMap: {
    T0: "#FF0000",
    T1: "#0000FF",
    T2: "#00FF00",
    T3: "#8080FF",
    T4: "#4040FF",
    T5: "#EDE275",
    T6: "#000000",
    T7: "#B87333",
    T8: "#A52A2A",
    T9: "#008000",
  },
  activeProfile: 0,
  profiles: [{ ...ProfileDefaults }, GeeetechMDefaults, GeeetechTDefaults, SingleExtruderDefaults],
  advanced: false,
  experimental: false,
});

const setVal = (obj, name, v) => (obj[name] = v != null ? v : obj.loadFromJson(name) || SettingDefaults[name]);

export default class Settings {
  constructor({ colorMap = null, activeProfile = null, profiles = null, advanced = null } = {}) {
    setVal(this, "colorMap", colorMap);
    setVal(this, "activeProfile", activeProfile);
    setVal(this, "profiles", profiles);
    setVal(this, "advanced", advanced);

    this.profiles.forEach(p => {
      if (p.retractionEnabled === undefined) p.retractionEnabled = ProfileDefaults.retractionEnabled;
      if (p.retractionDistance === undefined) p.retractionDistance = ProfileDefaults.retractionDistance;
      if (p.retractionSpeed === undefined) p.retractionSpeed = ProfileDefaults.retractionSpeed;
      if (p.primeDistance === undefined) p.primeDistance = ProfileDefaults.primeDistance;
    });

    this.setDynamicProps();
  }

  get active() {
    return this.profiles[this.activeProfile];
  }

  get octoValid() {
    return this.octoEnabled && this.active.octoHost.length > 0 && this.active.octoKey.length > 0;
  }

  get isSingleExtruder() {
    return this.active.toolCnt === 1;
  }

  setDynamicProps() {
    const prof = this.active;
    Object.keys(prof).forEach(key => (this[key] = prof[key]));

    const toolCnt = this.toolCnt > 1 ? this.toolCnt : 6; // Allow for different colors if only 1 extruder

    this.physicalTools = [...Array(toolCnt).keys()].map(n => "T" + n);
    this.virtualTools = [...Array(this.virtualToolCnt).keys()].map(n => "T" + (toolCnt + n));
    this.tools = [...this.physicalTools, ...this.virtualTools];
    this.tools.forEach(tool => {
      if (!this.colorMap[tool]) this.colorMap[tool] = "#008000";
    }, this);
  }

  copy({ colorMap = null, activeProfile = null, profiles = null, advanced = null, save = false } = {}) {
    const s = new Settings({
      colorMap: colorMap != null ? colorMap : { ...this.colorMap },
      activeProfile: activeProfile != null ? activeProfile : this.activeProfile,
      profiles: profiles != null ? profiles : this.profiles.map(p => ({ ...p })),
      advanced: advanced != null ? advanced : this.advanced,
    });

    if (save) s.save();

    return s;
  }

  reset() {
    const p = this.active;
    p.toolCnt = ProfileDefaults.toolCnt;
    p.virtualToolCnt = ProfileDefaults.virtualToolCnt;
    p.outFileFormat = ProfileDefaults.outFileFormat;
    p.filamentChangeText = ProfileDefaults.filamentChangeText;
    p.minStep = ProfileDefaults.minStep;

    return this;
  }

  save() {
    Object.keys(SettingDefaults).forEach(k => {
      localStorage.setItem(k, JSON.stringify(this[k]));
    });

    this.setDynamicProps();
  }

  loadFromJson(key) {
    try {
      return numBoolVal(JSON.parse(localStorage.getItem(key)));
    } catch (e) {
      return null;
    }
  }
}

export { Settings, ProfileDefaults };
