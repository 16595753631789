import React, { useState } from "react";

export default function Header({ onChangeAppState }) {
  const [boatKey, setBoatKey] = useState(0);

  return (
    <header>
      <h1 id="header-title" className="text-center hcenter">
        G-Code Mix Master
      </h1>
      <div id="header-buttons" className="vcenter">
        <div className="flex-row-reverse">
          <img
            id="btn-settings"
            className="btn header-btn-img"
            onClick={() => onChangeAppState({ settingsModal: true })}
            src={require("../resources/btn-settings.svg")}
            title="Settings"
            alt="⚙"
          />
          <img
            id="btn-info"
            className="btn header-btn-img"
            onClick={() => onChangeAppState({ infoModal: true })}
            src={require("../resources/btn-info.svg")}
            title="Information"
            alt="?"
          />
        </div>
      </div>
      <img
        id="img-boat"
        key={boatKey}
        className=""
        src={require("../resources/boat.svg")}
        onClick={() => setBoatKey(boatKey + 1)}
        alt=""
      />
    </header>
  );
}
