const rgb2ryb = require("rgb2ryb");

// Need to convert to ryb to do the mixing and then convert back to rgb
function rybToRgb(ryb) {
  const rgbArray = rgb2ryb.ryb2rgb(ryb);
  return { r: Math.round(rgbArray[0]), g: Math.round(rgbArray[1]), b: Math.round(rgbArray[2]) };
}

function mixColorsByWeight(tools, rgbMap, weights) {
  // Get the sum of the weights for only the tools in the list - switching from 3 extruders to 2 can leave T2 set still
  const totalWeight = tools.reduce((sum, t) => sum + (weights[t] || 0), 0);

  const rybVals = tools.reduce((map, tool) => {
    return { ...map, [tool]: rgb2ryb([rgbMap[tool].r, rgbMap[tool].g, rgbMap[tool].b]) };
  }, {});

  return rybToRgb([
    tools.reduce((sum, tool) => sum + (rybVals[tool][0] * (weights[tool] || 0)) / totalWeight, 0),
    tools.reduce((sum, tool) => sum + (rybVals[tool][1] * (weights[tool] || 0)) / totalWeight, 0),
    tools.reduce((sum, tool) => sum + (rybVals[tool][2] * (weights[tool] || 0)) / totalWeight, 0)
  ]);
}

function mixColorsByPct(c1, c2, pct) {
  const ryb1 = rgb2ryb([c1.r, c1.g, c1.b]);
  const ryb2 = rgb2ryb([c2.r, c2.g, c2.b]);

  return rybToRgb([
    ryb1[0] * (1 - pct) + ryb2[0] * pct,
    ryb1[1] * (1 - pct) + ryb2[1] * pct,
    ryb1[2] * (1 - pct) + ryb2[2] * pct
  ]);
}

function hexStringToRgb(hex) {
  hex = hex.replace("#", "");
  var val = parseInt(hex, 16);
  return {
    r: (val >> 16) & 255,
    g: (val >> 8) & 255,
    b: val & 255
  };
}

function rgbToHexString(rgb) {
  return `#${fmtVal(rgb.r)}${fmtVal(rgb.g)}${fmtVal(rgb.b)}`;
}

function fmtVal(v) {
  return v >= 16 ? v.toString(16) : "0" + v.toString(16);
}

function colorMapToRgbMap(colorMap, tools) {
  return tools.reduce((dct, t) => {
    return { ...dct, [t]: hexStringToRgb(colorMap[t]) };
  }, {});
}

// Put the color map back to what it would have been at the end height
// TODO - what if the next mod is a gradient mod? Then this will go after the first cmds for that mod.
function endColorMap(origColorMapTree, fileDetails, meta, endHeight) {
  const endColorMap = origColorMapTree.findFloor(
    endHeight >= fileDetails.obj.maxHeight ? fileDetails.lines.length - 1 : endHeight
  );
  meta.heightToColorMapTree.replace(endHeight, endColorMap);
}

export { mixColorsByPct, hexStringToRgb, mixColorsByWeight, rgbToHexString, colorMapToRgbMap, endColorMap };
