import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import { sessionInfo, DBG } from "./globals";

const DB_TEST = false;

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyD0FVvC4yMFaXRuXg34Mec09XyLmCu9St0",
    authDomain: "gcode-mix-master-react.firebaseapp.com",
    databaseURL: "https://gcode-mix-master-react.firebaseio.com",
    projectId: "gcode-mix-master-react",
    storageBucket: "gcode-mix-master-react.appspot.com",
    messagingSenderId: "1011821439478",
    appId: "1:1011821439478:web:e6f5ac2d945641c89e7fd7",
    measurementId: "G-VJHLNLEDRZ",
};

let analytics = null;
let db = null;
let exceptionCount = 0;
let assertCount = 0;

function firebaseInit() {
    if (!db) {
        firebase.initializeApp(FIREBASE_CONFIG);
        db = firebase.firestore();
    }
}

function gaInit() {
    if (DB_TEST) dbTest();

    if (localStorage.getItem("analyticsDisabled") === "true") {
        console.log("gaInit: Disabled");
        return;
    }

    firebaseInit();
    analytics = firebase.analytics();
    gaEvt("ga_init");
}

const dbEvt = (name, params) => {
    try {
        const obj = { name, ...params, timestamp: firebase.firestore.Timestamp.now() };
        db.collection("events")
            .add(obj)
            .then(function (docRef) { })
            .catch(function (error) {
                console.warn("dbEvt: ", error);
            });
    } catch (e) {
        console.log(e);
    }
};

const dbTest = () => {
    firebaseInit();

    const obj = { timestamp: firebase.firestore.Timestamp.now() };

    db.collection("test")
        .add(obj)
        .then(function (docRef) {
            console.log({ docRef });
        })
        .catch(function (error) {
            console.warn("dbEvtTest: ", error);
        });
};

function gaEvt(name, params = {}, options) {
    params = { ...sessionInfo, ...params };

    if (DBG) console.log(analytics ? "" : "GA DISABLED", { t: "ga_evt", name, params, options });

    if (!analytics) return;

    dbEvt(name, params);

    try {
        analytics.logEvent(name, params, options);
    } catch (e) {
        console.log(e);
    }
}

function gaException(e, loc, config) {
    if (++exceptionCount > 5) return;

    gaEvt("exception", {
        error: e.message || e,
        loc,
        stack: e.stack,
        e: JSON.stringify(e),
        exception_count: exceptionCount,
        config: JSON.stringify(config),
    });

    if (DBG) throw e;
}

function gaAssert(loc, params) {
    if (++assertCount > 5) return;

    gaEvt("assert", { loc, ...params });
}

const gaGetParams = (settings, config) => {
    const retraction = settings.retractionEnabled
        ? { retraction: `${settings.retractionDistance},${settings.retractionSpeed},${settings.primeDistance}` }
        : {};
    return config.mods.reduce(
        (map, m) => {
            map[m.type] = map[m.type] === undefined ? 1 : map[m.type] + 1;
            return map;
        },
        { tool_cnt: settings.toolCnt, min_step: settings.minStep, ...retraction }
    );
};

export { gaAssert, gaInit, gaEvt, gaException, gaGetParams };
