import React from "react";
import { Slider } from "./Slider";

export function SliderLabelled(props) {
  return (
    <div className={props.classes || "panel-item flex-row"} title={props.title}>
      <div className="vcenter">{props.label}</div>
      <Slider {...props} />
    </div>
  );
}
