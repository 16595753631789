import { detect } from "detect-browser";
import { v4 as uuid } from "uuid";
import { safeFn } from "./utils";
import gitId from "../resources/gitId.js";

const MAX_LEVELS = 1000;

const EMAIL = "gcodemixmaster@gmail.com";
const DBG = localStorage.getItem("DBG") === true || localStorage.getItem("DBG") === "true";
const browser = safeFn(detect, {});
const firstOrPrivate = localStorage.getItem("version") == null;
const sessionInfo = {
    os: browser.os,
    browser_name: browser.name,
    browser_version: browser.version,
    first_or_private: firstOrPrivate,
    device_id: localStorage.getItem("deviceId") || uuid(),
    storage_persists: null,
    commit_id: gitId.id
};

localStorage.setItem("deviceId", sessionInfo.device_id);

export { sessionInfo, firstOrPrivate, DBG, EMAIL, MAX_LEVELS };
