import { Mod } from "./Mod";
import { Commands } from "../Commands";
import { getFilamentChangeCmds, seededPRNG } from "../../utils/utils";

export default class ModRandoTool extends Mod {
    getError(settings) {
        return "";
    }

    getStartTool(meta) {
        const rng = seededPRNG(this.randoSeed);
        const tools = this.getTools(meta);
        return rngToTool(rng, tools);
    }

    fillMeta(settings, fileDetails, meta) {
        let prevLine = null;
        let prevTool = null;
        const obj = fileDetails.obj;
        const endHeight = Math.min(this.endHeight, obj.maxHeight + 0.00001);
        const totalHeight = endHeight - this.startHeight;
        if (totalHeight <= 0) return;

        const mmPerStep = totalHeight / this.randoLevels;
        const tools = this.getTools(meta);
        const toolArray = this.getToolArray(tools);

        for (let height = this.startHeight; height < endHeight; height += mmPerStep) {
            const tool = toolArray.shift();

            const curLine = obj.heightToLineTree.findCeiling(height);
            if (curLine === prevLine) continue; // Avoid doing multiple changes on the same line

            prevLine = curLine;

            if (prevTool === tool) continue;

            prevTool = tool;
            const cmds = settings.isSingleExtruder ? getFilamentChangeCmds(settings, "T0") : [tool];

            meta.lineToToolTree.replaceIfChanged(curLine, tool);
            meta.newCommands.add(curLine, new Commands(this, height, cmds));
        }
    }

    getTools(meta) {
        return meta.tools.filter(t => this.randoTools[t] === undefined || this.randoTools[t]);
    }

    /**
     * Get an array that has a tool for each height level and boomerangs if enabled
     **/
    getToolArray(tools) {
        const rng = seededPRNG(this.randoSeed);
        const indexToTool = [];
        const subVal = this.randoLevels - 1;

        for (let i = 0; i < this.randoLevels; i++) {
            const t = !this.boomerang || i < this.randoLevels / 2 ? rngToTool(rng, tools) : indexToTool[subVal - i];
            indexToTool.push(t);
        }

        // Sometimes there is an extra little sliver of height at the end due to float math
        // Repeat the last tool once just in case
        indexToTool.push(indexToTool[indexToTool.length - 1]);

        return indexToTool;
    }
}

function rngToTool(rng, tools) {
    return tools[Math.round(rng() * tools.length) % tools.length];
}
