export default class Change {
  constructor(op, origLine, newLine, mod, height, text) {
    this.op = op;
    this.origLine = origLine;
    this.newLine = newLine;
    this.mod = mod;
    this.height = Math.round(100 * height) / 100;
    this.text = text;
  }
}
