import React, { useState } from "react";
import streamSaver from "streamsaver";
import { saveAs } from "file-saver";

import { isProcessed, process } from "../utils/process";
import { cogoWarn, cogoError } from "../utils/cogo";
import { getOutFileName } from "../utils/utils";
import { gaException, gaEvt, gaGetParams } from "../utils/ga";

const COOKIE_WARNING = `If the file is not saving, please try enabling Third-Party Cookies in the browser settings or try another browser.`;

export default function ButtonFileSave({ settings, fileDetails, config, meta, updateChangeLines, saveWarning }) {
    const [animating, setAnimating] = useState(false);

    const onSave = ev => {
        const savedAgain = isProcessed(settings, config, fileDetails, meta);

        try {
            ev.preventDefault();

            saveWarning();

            const [outLines, changeLines] = process(settings, config, fileDetails, meta);
            const filename = getOutFileName(settings, fileDetails, config);

            saveFile(filename, outLines);

            updateChangeLines(changeLines);
            if (savedAgain) cogoWarn(COOKIE_WARNING);
        } catch (e) {
            cogoError(`There was an error trying to save the file.  If the problem persists, try another browser.`);
            gaException(e, "onSave", config);
        }

        function saveFile(fileName, content) {
            const blob = new Blob([content.join("")], { type: "text/plain;charset=utf-8" });
            console.log("Saving " + fileName);

            if (!streamSaver || !streamSaver.WritableStream || !blob.stream) return saveFileAsElement(fileName, blob);

            console.log("saveFile : Fast saving");

            const fileStream = streamSaver.createWriteStream(fileName, { size: blob.size }); // Makes the % visible in the browser

            const readableStream = blob.stream();

            if (window.WritableStream && readableStream.pipeTo) {
                reportEvt("pipe");
                return readableStream.pipeTo(fileStream).then(() => console.log("Save complete for " + fileName));
            }

            window.writer = fileStream.getWriter();

            const reader = readableStream.getReader();
            const pump = () =>
                reader
                    .read()
                    .then(res => (res.done ? window.writer.close() : window.writer.write(res.value).then(pump)));

            reportEvt("pump");
            pump();
        }

        function saveFileAsElement(fileName, blob) {
            cogoWarn("Saving files may be slow on this browser. Chrome is recommended.");

            if (window.navigator.msSaveOrOpenBlob) {
                console.log("saveFileAsElement : Slow saving as blob");
                reportEvt("blob");
                return window.navigator.msSaveOrOpenBlob(blob, fileName); // IE10+
            }

            console.log("saveFileAsElement : Slow saving using saveAs");
            reportEvt("save_as");
            saveAs(blob, fileName);
            console.log("saveFileAsElement : File saved");
        }

        function reportEvt(saveMode) {
            if (!fileDetails.isDemo) {
                const params = gaGetParams(settings, config);
                gaEvt("save_file", { ...params, save_mode: saveMode, saved_again: savedAgain });
            } else {
                console.log(`reportEvt : No event for demo file`);
            }
        }
    };

    if (!fileDetails.fileName || config.getError(settings, fileDetails)) return null;

    return (
        <div
            id="btn-save"
            className={"file-btn-div" + (animating ? " file-btn-div-animate" : "")}
            onClick={ev => {
                setAnimating(true);
                onSave(ev);
            }}
            onAnimationEnd={() => setAnimating(false)}
            title="Generate the new G-code file and save it locally"
        >
            <img className="btn file-btn-img" src={require("../resources/btn-save.svg")} alt="Save" />
        </div>
    );
}

export { ButtonFileSave };
