import React, { Component } from "react";

const HEIGHT_TIP =
  "This height is the bottom height for the layer and not the nozzle height which is at the top of the layer";

export default class ChangeDetails extends Component {
  render() {
    return (
      <div className="panel">
        <div className="panel-title">Resulting G-Code Changes</div>
        <div className="panel-contents">
          <table id="change-table">
            <thead>
              <tr>
                <th>OP</th>
                <th>LINE</th>
                <th>MOD</th>
                <th title={HEIGHT_TIP}>HEIGHT</th>
                <th>G-CODE</th>
              </tr>
            </thead>
            <tbody>{this.props.changeLines.map((cl, idx) => changeLineToRow(cl, idx))}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function changeLineToRow(cl, idx) {
  return (
    <tr className={cl.op === "ADD" ? "op-add" : "op-rem"} key={idx}>
      <td>{cl.op}</td>
      <td title={`Original line : ${cl.origLine}`}>{cl.newLine}</td>
      <td>{cl.mod}</td>
      <td title={HEIGHT_TIP}>{cl.height}</td>
      <td>{cl.text}</td>
    </tr>
  );
}
