import { Mod } from "./Mod";
import { Commands } from "../Commands";

export default class ModHeightPatterns extends Mod {
  fillMeta(settings, fileDetails, meta) {
    const endHeight = Math.min(this.endHeight, fileDetails.obj.maxHeight + 0.00001);

    let node = fileDetails.obj.heightToLineTree.findCeilingNode(this.startHeight);
    for (; node.key < endHeight; node = fileDetails.obj.heightToLineTree.nextNode(node)) {
      const next = fileDetails.obj.heightToLineTree.nextNode(node);

      meta.lineToToolTree.replaceIfChanged(node.data);

      let prevTool = meta.lineToToolTree.findFloor(node.data);

      for (let [p, i] in this.patterns) {
        const line = Math.floor((i * (node.data + next.data)) / (this.patterns.length - 1));
        meta.newCommands.add(
          line,
          new Commands(this, node.key, this.getToolChangeCmds(settings, p.tool, prevTool, node.key))
        );
        prevTool = p.tool;
      }
    }
  }

  getStartTool(meta) {
    return this.patterns[0].tool;
  }
}
