import React, { Component } from "react";
import YesNoItem from "./YesNoItem";
import { range, toolSelect, getT0PctFromWeights } from "./ComponentCommon";
import { boomOdd, getMaxLevelsByStep, numberOrValue } from "../utils/utils";
import { ButtonAdd } from "./ButtonAdd";

const BOOM_TIP = "If enabled, the gradient will transition from its start mix to its end mix and back";
const GRADIENT_OVERLAP_TIP =
  "Enabled this advanced feature to apply a gradient to a physical tool while leaving tool changes for another " +
  "pattern or the original file in place.  See the FAQ for more details.";
const GRADIENT_REPEAT_CNT_TIP = "The number of times to repeat the full gradient within the start and end heights";
const GRADIENT_TOOL_TIP = "The tool that will have a gradient applied to it.";

export default class ModPanelGradient extends Component {
  render() {
    const [m, settings, onChg] = [this.props.m, this.props.settings, this.props.onChangeValue];
    const maxLevels = boomOdd(m, getMaxLevelsByStep(settings, this.props.fileDetails));
    const minLevels = boomOdd(m, 2);
    const levels = boomOdd(m, m.gradientLevels);
    const levelStep = m.boomerang ? 2 : 1; // Must be odd for boomerang

    return (
      <React.Fragment>
        <div className="panel-contents">
          <YesNoItem label="Boomerang" name="boomerang" value={m.boomerang} onChange={onChg} title={BOOM_TIP} />
          <YesNoItem label="Overlap" name="overlap" value={m.overlap} onChange={onChg} title={GRADIENT_OVERLAP_TIP} />
          {m.overlap
            ? toolSelect(settings, m, settings.physicalTools, "Mix Tool", "mixTool", "", GRADIENT_TOOL_TIP, onChg)
            : null}
          {range("Count", "gradientRepeatCount", m.gradientRepeatCount, 1, 50, GRADIENT_REPEAT_CNT_TIP, onChg)}
          {range("Levels", "gradientLevels", levels, minLevels, maxLevels, "", onChg, levelStep)}
        </div>
        {this.gradientWeights(m)}
      </React.Fragment>
    );
  }

  gradientWeights(m) {
    return this.props.settings.toolCnt > 2 ? this.gradientWeightsManyTools(m) : this.gradientWeightsTwoTools(m);
  }

  gradientWeightsTwoTools(m) {
    return (
      <div className="panel-contents">
        {range("Start T0 %", "mix-0", getT0PctFromWeights(m.gradientWeights[0]), 0, 100, "", this.onChangePct)}
        {range("End T0 %", "mix-1", getT0PctFromWeights(m.gradientWeights[1]), 0, 100, "", this.onChangePct)}
      </div>
    );
  }

  gradientWeightsManyTools(m) {
    return (
      <React.Fragment>
        <div className="panel-contents flex-row">{m.gradientWeights.map((w, i) => this.byWeights(m, i))}</div>
        <ButtonAdd title="Add a mix" onClick={this.props.onAddMix} />
      </React.Fragment>
    );
  }

  byWeights(m, idx) {
    const src = require("../resources/btn-x.svg");

    return (
      <div key={idx} className="panel-item flex-column" style={{ position: "relative" }}>
        <div className="panel-data vcenter" style={{ marginBottom: "7px" }}>
          Mix {idx + 1}
        </div>
        <img
          className="btn btn-small btn-mix-delete btn-delete"
          src={src}
          onClick={() => this.props.onDeleteMix(idx)}
          title="Delete mix"
          alt="x"
        />
        {this.props.settings.physicalTools.map(t => this.weightForTool(m, t, idx))}
      </div>
    );
  }

  weightForTool(m, tool, idx) {
    const weight = m.gradientWeights[idx][tool] || 0;
    const sum = this.props.settings.physicalTools.reduce((sum, t) => sum + (m.gradientWeights[idx][t] || 0), 0);
    const pct = sum ? Math.round((100 * weight) / sum) : 0;
    const fn = ev => this.onChangeWeights(ev, idx, tool);

    return (
      <div className="flex-row panel-data vcenter" key={tool}>
        {range(tool, "mix-" + idx, weight, 0, 255, "", fn, 1, "flex-row vcenter")}
        <div className="panel-data" style={{ width: "4ch", textAlign: "right" }}>
          {pct}%
        </div>
      </div>
    );
  }

  onChangeWeights(ev, idx, tool) {
    const w = this.props.m.gradientWeights.slice(); // Clone the array
    w[idx] = { ...w[idx], [tool]: Number(ev.target.value) };

    this.props.onChangeField("gradientWeights", w);
  }

  onChangePct = ev => {
    const idx = Number(ev.target.name.slice(4));
    const pct = numberOrValue(ev.target.value);
    const w = this.props.m.gradientWeights.slice(); // Clone the array
    w[idx] = { ...w[idx], T0: pct, T1: 100 - pct };

    this.props.onChangeField("gradientWeights", w);
  };
}
